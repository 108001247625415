import request from '@hjgy/common/request';

/**
 * 保存当前品牌商设置
 */
export const putBrandBusiness = (data) => request.post('/brand-business-setting', data);

/**
 * 保存指定品牌商设置
 */
export const saveBrandBusiness = (data) => request.post('/brand-business-setting/save', data);

/**
 * 根据设置项编码查询当前登录用户品牌商设置列表
 */
export const getSettingsByCodes = ({ codes }) =>
  request.get(`/brand-business-setting/list/codes`, { params: { codes } });

/**
 * 根据设置项编码查询当前品牌商设置
 */
export const getSettingsByCode = ({ code }) => request.get(`/brand-business-setting/code/${code}`);

/**
 * 数据墙配置信息
 */
export const getDatavInfo = (brandBusinessId) => request.get(`/datav-config/config-info/${brandBusinessId}`);

/**
 * 查询系统设置信息
 */
export const getBrandBusinessDetail = ({ id }) => request.get(`/brand-business/detail/${id}`);

/**
 * 根据设置项编码查询品牌商设置
 */
export const getByCode = ({ code }) => request.get(`/brand-business-setting/code/${code}`);
