// utils
import persistedState from '@hjgy/common/persistedState';

import { namespace, getters as $getters, actions as $actions, mutations as $mutations } from './types';

/**
 * state
 */
const state = persistedState(
  namespace,
  {},
  {
    // ...other
    templateIndex: 0,
  },
);

/**
 * getters
 */
const getters = {
  [$getters.templateIndex]: (state) => state.templateIndex,
};

/**
 * actions
 */
const actions = {
  [$actions.saveTemplateIndex]({ commit }, { templateIndex }) {
    commit($mutations.saveTemplateIndex, { templateIndex });
  },
};

/**
 * mutations
 */
const mutations = {
  [$mutations.saveTemplateIndex](state, { templateIndex }) {
    state.templateIndex = templateIndex || state.templateIndex;
  },
};

export default {
  namespaced: true,

  state,

  getters,

  actions,

  mutations,
};
