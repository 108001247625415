<script>
import { Badge, TabPane } from 'element-ui';

const El = {
  Badge,
  TabPane,
};

export default {
  name: 'main-layout-tab-pane',
  functional: true,
  props: {
    name: String,
    label: String,
    badge: {},
  },
  methods: {},
  render(h, context) {
    const data = {
      ...context.data,
      props: {
        name: context.props.name,
        label: context.props.label,
      },
    };
    return (
      <El.TabPane {...data}>
        <span slot="label" class="main-layout-tab-pane__content">
          {context.slots().default || context.props.label}
          {!!context.props.badge && <El.Badge class="main-layout-tab-pane__badge" value={context.props.badge} />}
        </span>
      </El.TabPane>
    );
  },
};
</script>

<style lang="postcss">
@import '~@/styles/base/var.css';

.main-layout-tab-pane__content {
  & .main-layout-tab-pane__badge {
    font-weight: normal;
    line-height: 20px;

    & .el-badge__content {
      margin-left: 5px;
    }
  }
}
</style>
