export const namespace = 'logistics';

export const getters = {
  logisticsInfo: 'logisticsInfo',
  kd100Logistics: 'kd100Logistics',
};

export const actions = {
  setLogisticsInfo: 'setLogisticsInfo',
  setKd100Logistics: 'setKd100Logistics',
};

export const mutations = {
  changeLogisticsInfo: 'changeLogisticsInfo',
  changeKd100Logistics: 'changeKd100Logistics',
};
