/**
 * state
 */
const state = {};

/**
 * getters
 */
const getters = {};

/**
 * actions
 */
const actions = {};

/**
 * mutations
 */
const mutations = {};

export default {
  namespaced: true,

  state,

  getters,

  actions,

  mutations,
};
