// 自定义关键字（替换关键字）
// 关键字
const customize = require('../i18n/messages/zh-cn').default.customize;

export function customizeLabel(customizeMap, label) {
  let labelMsg = label;
  for (let i in customize) {
    if (labelMsg.indexOf(customize[i]) !== -1) {
      labelMsg = labelMsg.replace(customize[i], customizeMap[i]);
    }
  }
  return labelMsg;
}
