export { default as MenuLayout } from './MenuLayout.vue';
export { default as MenuLayoutMenu } from './MenuLayoutMenu.vue';
export { default as MenuLayoutMenuItem } from './MenuLayoutMenuItem.vue';

import MenuLayout from './MenuLayout.vue';
import MenuLayoutMenu from './MenuLayoutMenu.vue';
import MenuLayoutMenuItem from './MenuLayoutMenuItem.vue';

export function install(Vue) {
  Vue.component('MenuLayout', MenuLayout);
  Vue.component('MenuLayoutMenu', MenuLayoutMenu);
  Vue.component('MenuLayoutMenuItem', MenuLayoutMenuItem);
}
