export const namespace = 'account';

export const getters = {
  loginInfo: 'loginInfo',
  loginSession: 'loginSession',
  currentUser: 'currentUser',
  brandBusiness: 'brandBusiness',
  useCentralBankModel: 'useCentralBankModel',
  isCenterCloudModel: 'isCenterCloudModel',
  shopThirdSend: 'shopThirdSend',
  userThirdSend: 'userThirdSend',
  enablePoints: 'enablePoints',
  enableWareHouseInout: 'enableWareHouseInout',
  enableCrowdWarehouse: 'enableCrowdWarehouse',
  userThirdWarehouse: 'userThirdWarehouse',
  shopThirdWarehouse: 'shopThirdWarehouse',
  setSecurityPassword: 'setSecurityPassword',
  thirdPayWay: 'thirdPayWay',
  isJoinPay: 'isJoinPay',
  enableOccupy: 'enableOccupy',
};

export const actions = {
  setLoginSession: 'setLoginSession',
  login: 'login',
  logout: 'logout',
  setBrandBusiness: 'setBrandBusiness',
  getCurrentUser: 'getCurrentUser',
  setI18nMessages: 'setI18nMessages',
  setSystemSetting: 'setSystemSetting',
  setShopThirdSend: 'setShopThirdSend',
  setShopThirdWarehouse: 'setShopThirdWarehouse',
  setCustomizeSetting: 'setCustomizeSetting',
  setAll: 'setAll',
  setSecurityPasswordConfig: 'setSecurityPasswordConfig',
  setConfig: 'setConfig',
  setOccupy: 'setOccupy',
};

export const mutations = {
  loginSuccess: 'loginSuccess',
  logoutSuccess: 'logoutSuccess',
  setBrandBusiness: 'setBrandBusiness',
  getCurrentUserSuccess: 'getCurrentUserSuccess',
  setShopThirdSend: 'setShopThirdSend',
  setShopThirdWarehouse: 'setShopThirdWarehouse',
  setUserThirdSend: 'setUserThirdSend',
  setEnablePoints: 'setEnablePoints',
  setWareHouseInout: 'setWareHouseInout',
  setCrowdWarehouse: 'setCrowdWarehouse',
  setUserThirdWarehouse: 'setUserThirdWarehouse',
  changeAll: 'changeAll',
  changeSecurityPasswordConfig: 'changeSecurityPasswordConfig',
  changeUpConfig: 'changeUpConfig',
  setThirdPayWay: 'setThirdPayWay',
  setIsJoinPay: 'setIsJoinPay',
  changeOccupy: 'changeOccupy',
};
