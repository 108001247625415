<script>
import Header from './Header.vue';

export default {
  name: 'main-layout',
  props: {
    title: String,
  },
  data() {
    return {
      created: false,
      // footer: null
    };
  },
  computed: {},
  methods: {
    updateDefaultSlots() {
      const slots = {};

      if (!this.created) return slots;

      this.$slots.default.forEach((vnode) => {
        const name = vnode.componentOptions && vnode.componentOptions.Ctor && vnode.componentOptions.Ctor.options.name;

        const slotName =
          {
            'main-layout-header': 'header',
            'main-layout-footer': 'footer',
          }[name] || 'content';

        slots[slotName] = slots[slotName] || [];
        slots[slotName].push(vnode);
      });

      this.defaultSlots = slots;
    },
    renderHeader() {
      if (this.defaultSlots.header) return this.defaultSlots.header;

      if (this.title) {
        return <Header>{this.title}</Header>;
      }
    },

    setFooter(footer) {
      this.$refs.footer.appendChild(footer);
    },
  },

  created() {
    this.created = true;
  },
  render() {
    this.updateDefaultSlots();

    return (
      <div class="main-layout">
        {this.renderHeader()}

        <div class="main-layout__body">{this.defaultSlots.content}</div>

        <div class="main-layout__footer" ref="footer">
          {this.defaultSlots.footer}
        </div>
      </div>
    );
  },
};
</script>

<style lang="postcss">
@import '~@/styles/base/var.css';

.main-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: rgba(242, 242, 242, 1);

  & .main-layout__body {
    box-sizing: border-box;
    flex: 1;
    overflow: auto;
  }

  & .main-layout__footer {
    position: relative;
  }
}
</style>
