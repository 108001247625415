// utils
import persistedState from '@hjgy/common/persistedState';
import * as logisticsApi from '@/apis/logisticsApi';

import { namespace, getters as $getters, actions as $actions, mutations as $mutations } from './types';

/**
 * state
 */
const state = persistedState(
  namespace,
  {
    logisticsInfo: null,
  },
  {
    kd100Logistics: null,
  },
);

/**
 * getters
 */
const getters = {
  [$getters.logisticsInfo]: (state) => state.logisticsInfo,
  [$getters.kd100Logistics]: (state) => state.kd100Logistics,
};

/**
 * actions
 */
const actions = {
  [$actions.setLogisticsInfo]({ commit }, { logistics }) {
    commit($mutations.changeLogisticsInfo, { logistics });
  },

  [$actions.setKd100Logistics]: async ({ commit }, brandBusinessId) => {
    if (!brandBusinessId) return;
    const { dto } = await logisticsApi.logisticsTraceGetSubscription(brandBusinessId).catch((e) => e);
    if (dto?.errCode) return;
    // kd100:未开通或者停用的情况，就用快递100免费查询物流轨迹信息
    let kd100 = true;
    if (dto) {
      kd100 = dto.isStop;
    }
    commit($mutations.changeKd100Logistics, kd100);
  },
};

/**
 * mutations
 */
const mutations = {
  [$mutations.changeLogisticsInfo](state, { logistics }) {
    state.logisticsInfo = logistics;
  },

  [$mutations.changeKd100Logistics](state, value) {
    state.kd100Logistics = value;
  },
};

export default {
  namespaced: true,

  state,

  getters,

  actions,

  mutations,
};
