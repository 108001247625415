import request from '@hjgy/common/request';

/**
 * 根据设置项编码查询
 */
export const getPointsConfig = (params) => request.get('/points-config/list/code', { params });

/**
 * 保存
 */
export const savePointsConfig = (data) => request.post('/points-config/save', data);

/**
 * 积分审核详情
 */
export const getPointsDetail = (id) => request.get(`/points-exchange/detail/${id}`);

/**
 * 获取用户未结束的type类型的积分兑换审核
 */
export const getExchangePoints = (params) => request.get('/points-exchange/exchanging', { params });

/**
 * 查询用户是否有未结束的type类型的积分兑换审核
 */
export const existsExchanging = () => request.get('/points-exchange/exists-exchanging');

/**
 * 积分审核列表
 */
export const getPointsList = (params) => request.get('/points-exchange/list', { params });

/**
 *  获取积分兑换审核某状态的总数量
 */
export const getPointsTotal = (params) => request.get('/points-exchange/status/total', { params });

/**
 * 积分兑换审核通过
 */
export const passPointsExchange = (data) => request.post(`/points-exchange/pass`, data);

/**
 * 积分兑换审核拒绝
 */
export const rejectPointsExchange = (data) => request.post('/points-exchange/reject', data);

/**
 * 修改代理积分提现申请的收款账户信息
 */
export const updatePointsAccount = (data) => request.post('/points-exchange/update', data);

/**
 *  导出积分兑换审核
 */
export const exportPointsExchange = (params) => request.get('/points-exchange/export', { params });

/**
 *  最近一次导出积分兑换审核的信息
 */
export const latestExportPoints = (params) => request.get('/points-exchange/latest-export', { params });
