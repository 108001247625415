export const namespace = 'mall';

export const getters = {
  templateIndex: 'templateIndex',
};

export const actions = {
  saveTemplateIndex: 'saveTemplateIndex',
};

export const mutations = {
  saveTemplateIndex: 'saveTemplateIndex',
};
