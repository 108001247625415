import request from '@hjgy/common/request';

/**
 * 查询关键字列表
 */
export const getCustomizeList = (data) => request.post(`/brand-business-custom-word/list`, data);

/**
 * 修改自定义关键字
 */
export const setCustomizeWorld = (data) => request.post('/brand-business-custom-word/update', data);
