<script>
import { Breadcrumb, BreadcrumbItem } from 'element-ui';

const El = { Breadcrumb, BreadcrumbItem };

export default {
  name: 'main-layout-breadcrumb',
  components: {},
  props: {},
  methods: {},
  render() {
    return (
      <div class="main-layout-breadcrumb">
        <El.Breadcrumb>{this.$slots.default}</El.Breadcrumb>
      </div>
    );
  },
};
</script>

<style lang="postcss">
@import '~@/styles/base/var.css';

.main-layout-breadcrumb {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 30px;
}
</style>
