// config
import config from './config';

import Vue from 'vue';
import 'default-passive-events';

// moment
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
// 自定义文案
import { customizeLabel } from './utils/customize';
import Print from './utils/print';
// 时区转换
require('moment-timezone/moment-timezone');
const zones = {
  version: '2018e',
  zones: [
    'Asia/Shanghai|CST CDT|-80 -90|01010101010101010|-1c1I0 LX0 16p0 1jz0 1Myp0 Rb0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0|23e6',
  ],
  links: [],
};
moment.tz.load(zones);
moment.tz.setDefault('Asia/Shanghai');

Vue.prototype.$moment = moment;
Vue.prototype.$customizeLabel = customizeLabel;

// vue-i18n
import i18n from './i18n';

// request
import request from '@hjgy/common/request';

request.defaults.baseURL = config.apiUrl;

let isReset = false;
// Add a response interceptor
request.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    if (error.errCode === 498 || error.errCode === 496) {
      // 未授权
      if (!isReset) {
        isReset = true;

        if (error.errCode === 504) alert('请求超时，请稍后重试');

        if (error.errCode === 498) {
          alert('登录信息已过期，请重新登录');
          window.location.href = `/login?redirect=${window.encodeURIComponent(window.location.pathname)}`;
        } else if (error.errCode === 496) {
          alert('当前账号在其他地方登录');
          window.location.href = `/login?redirect=${window.encodeURIComponent(window.location.pathname)}`;
        }
      }
    }
    return Promise.reject(error);
  },
);

// directives
import directives from './directives';

Object.keys(directives).forEach((name) => Vue.directive(name, directives[name]));

// filters
import filters from './filters';

Object.keys(filters).forEach((name) => Vue.filter(name, filters[name]));

// components
import './components';

// store
import store from './store';

// router
import router from './router';

// permission
import AsyncData from '@hjgy/common/plugins/AsyncData';
import IsAccess from '@hjgy/common/plugins/IsAccess';

Vue.use(AsyncData, {
  router,
  before() {},
  beforeEach({ to, component }) {
    if (to.meta && to.meta.notLogin) {
      // 不需要登录,直接跳过
      return;
    }
    component.permissions = component.permissions || [];
  },
  after() {},
});
Vue.use(IsAccess, {
  router,
  after() {
    router.push(`/notAllow`);
  },
});
Vue.use(Print);

Vue.mixin({
  created() {},
});

router.beforeResolve((to, from, next) => {
  setTimeout(() => {
    window.initSuccess && window.initSuccess();
  }, 300);
  next();
});

// 将store与router同步
import { sync } from 'vuex-router-sync';

sync(store, router);

import App from './App.vue';

const app = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
});

router.onReady(() => {
  app.$mount('#app');
});
