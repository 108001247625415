export const namespace = 'shopReward';

export const getters = {
  rewardSpan: 'rewardSpan',
};

export const actions = {
  setRewardSpan: 'setRewardSpan',
};

export const mutations = {
  changeRewardSpan: 'changeRewardSpan',
};
