// 注册element-ui
import Vue from 'vue';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  CollapseItem,
  DatePicker,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Form,
  FormItem,
  Input,
  InputNumber,
  Loading,
  Link,
  Menu,
  MenuItem,
  Option,
  Popover,
  Pagination,
  Radio,
  RadioButton,
  RadioGroup,
  Rate,
  Row,
  Select,
  Steps,
  Step,
  Submenu,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Tooltip,
  Upload,
  Tree,
  CheckboxGroup,
  Cascader,
  Switch,
  TreeTable,
  TreeTableColumn,
  Progress,
} from 'element-ui';

// Loading 不是组件，是指定
Loading.install(Vue);
Popover.install(Vue);
Vue.use(Popover);

const components = [
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  CollapseItem,
  DatePicker,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Form,
  FormItem,
  Input,
  InputNumber,
  Link,
  Menu,
  MenuItem,
  Option,
  Popover,
  Pagination,
  Radio,
  RadioButton,
  RadioGroup,
  Rate,
  Row,
  Select,
  Steps,
  Step,
  Submenu,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Tooltip,
  Upload,
  Tree,
  CheckboxGroup,
  Cascader,
  Switch,
  TreeTable,
  TreeTableColumn,
  Progress,
];

components.forEach((commponent) => Vue.component(commponent.name, commponent));
