<script>
import Vue from 'vue';
import { Loading } from 'element-ui';

Loading.install(Vue);

export default {
  name: 'main-layout-body',
  props: {
    loading: Boolean,
    contentClass: String,
  },
  methods: {},
  render() {
    return (
      <div class="main-layout-body">
        <div class={['main-layout-body__content', this.contentClass]} v-loading={this.loading}>
          {this.$slots.default}
        </div>
      </div>
    );
  },
};
</script>

<style lang="postcss">
@import '~@/styles/base/var.css';

.main-layout-body {
  position: relative;
  padding: 10px;
  width: 100%;
  transform: none;

  & .main-layout-body__content {
    position: relative;
    background: #fff;
    border: 1px solid #e4e7ed;
    padding: 20px;
  }
}
</style>
