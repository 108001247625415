export { default as Layout } from './Layout.vue';
export { default as Header } from './Header.vue';
export { default as Tabs } from './Tabs.vue';
export { default as TabPane } from './TabPane.vue';
export { default as Breadcrumb } from './Breadcrumb.vue';
export { default as BreadcrumbItem } from './BreadcrumbItem.vue';
export { default as Body } from './Body.vue';
export { default as Footer } from './Footer.vue';

import Layout from './Layout.vue';
import Header from './Header.vue';
import Tabs from './Tabs.vue';
import TabPane from './TabPane.vue';
import Breadcrumb from './Breadcrumb.vue';
import BreadcrumbItem from './BreadcrumbItem.vue';
import Body from './Body.vue';
import Footer from './Footer.vue';

export function install(Vue) {
  Vue.component('MlLayout', Layout);
  Vue.component('MlHeader', Header);
  Vue.component('MlTabs', Tabs);
  Vue.component('MlTabPane', TabPane);
  Vue.component('MlBreadcrumb', Breadcrumb);
  Vue.component('MlBreadcrumbItem', BreadcrumbItem);
  Vue.component('MlBody', Body);
  Vue.component('MlFooter', Footer);
}
