import request from '@hjgy/common/request';

/**
 * 查询快递公司编码信息列表
 */
export const getLogistics = (data) => request.post('/logistics/shipper-code-list', data);

/**
 * 查询快递单号的物流轨迹
 */
export const logisticsTraces = ({ logisticCode }) => request.get(`/logistics/traces/${logisticCode}`);

/**
 * 根据发货订单id集查询申请成功的物流单号信息
 */
export const getEOrdersByIds = (params) => request.get(`/logistics/query-eorders`, { params });

/**
 * 根据面单id集合查询电子面单信息
 */
export const getEOrderByInvoiceIds = (data) => request.post(`/logistics/query-eorder-by-invoiceIds`, data);

/**
 * 根据id查询电子面单信息
 */
export const getEOrdersInfoById = (id) => request.get(`/logistics/query-eorder/${id}`);

/**
 * 根据id集查询电子面单信息
 */
export const getEOrdersInfoByIds = (param) => request.post(`/logistics/query-eorder/list`, param);

/**
 * 根据发货单id查询电子面单信息
 */
export const getEOrdersByInvoiceId = (invoiceId) => request.get(`/logistics/get-by-invoice-id/${invoiceId}`);

/**
 * 查询电子面单快递客户号信息
 */
export const getEOrdersList = (params) => request.get(`/logistics/query-eorder/customer-list`, { params });

/**
 * 获取散户模式快递信息和代理商的客户号快递信息
 */
export const getRetailOrdersList = (params) => request.get(`/logistics/query-eorder/retail-customer-list`, { params });
/**
 * 获取散户模式快递信息和代理商的客户号快递信息-根据编码查询
 */
export const getRetailOrdersListByShipperCode = ({ shipperCode }) =>
  request.get(`/logistics/query-eorder/retail-customer-list/by-code/${shipperCode}`);

/**
 * 保存电子面单客户号信息
 */
export const saveEOrderInfo = (data) => request.post('/logistics/save-customer', data);

/**
 * 申请电子面单
 */
export const applyEorder = (data) => request.post(`/logistics/apply-eorder`, data);

/**
 * 删除电子面单客户号信息
 */
export const delEOrderInfo = (id) => request.get(`/logistics/query-eorder/customer/delete/${id}`);

/**
 * 根据订单id查询上一个申请后没被使用的电子面单信息
 */
export const queryOrderId = (orderId) => request.get(`/logistics/query-order-id/${orderId}`);

/**
 * 根据发货单ID查询上一个申请后没被使用的电子面单信息
 */
export const queryEleOrderByInvoiceId = (invoiceId) => request.get(`/logistics/query-invoice-id/${invoiceId}`);

/**
 * 根据id删除电子面单信息
 */
export const delEorder = (id) => request.get(`/logistics/del-eorder/${id}`);

/**
 * 根据订单id集查询对应订单的上一个申请后没被使用的电子面单信息
 */
export const queryOrderIds = (data) => request.post(`/logistics/query-order-ids`, data);

/**
 * 根据id集删除电子面单信息
 */
export const delEorders = (data) => request.post(`/logistics/del-eorders`, data);

/**
 * 手动输入物流信息关联订单
 */
export const saveLinkLogistics = (data) => request.post(`/logistics/link-logistics`, data);

/**
 * 查询电子面单授权信息
 */
export const getLogisticsAuthInfo = () => request.get('/logistics-cn/auth-info/query');

/**
 * 保存电子面单授权信息
 */
export const saveLogisticsAuthInfo = (data) => request.post('/logistics-cn/auth-info/save', data);

/**
 * 申请菜鸟电子面单
 */
export const applyCaiNiaoEorder = (data) => request.post('/logistics-cn/get-way-bill', data);

/**
 * 申请菜鸟电子面单(按订单)
 */
export const applyCaiNiaoEorderByOrder = (data) => request.post('/logistics-cn/get-way-bill/with-order', data);

/**
 * 查询商家的菜鸟面单订购关系列表
 */
export const getCaiNiaoSubscriptionShipperList = () => request.get('/logistics-cn/query-subscription');
/**
 * 查询菜鸟对应快递公司信息列表
 */
export const logisticsCnCompanyList = () => request.get(`/logistics-cn/company-list/`);

/**
 * 查询商家的菜鸟面单订购关系列表
 */
export const logisticsQuerySubscription = () =>
  request.get(`/logistics-cn/query-subscription/`).then(
    (list) =>
      // 没有数据时，接口返回空
      list || [],
  );

/**
 * 根据物流公司编码查询下所有业务类型
 */
export const getLogisticsAllExpress = ({ code }) => request.get(`/logistics/get-express/by-code/${code}`);

/**
 * 根据快递模板id查询业务类型
 */
export const getLogisticsCustomerExpress = ({ id }) => request.get(`/logistics/get-customer/express-profession/${id}`);

/**
 * 查询品牌商上一次发货物流信息
 */
export const getPerLogisticsInfo = () => request.get(`/invoice/get/per-send-log`);

/**
 * 批量申请快递鸟电子面单
 */
export const batchApplyLogistics = (data) => request.post(`/logistics/apply-eorder/batch`, data);

/**
 * 批量申请快递鸟电子面单(按订单)
 */
export const batchApplyLogisticsByOrder = (data) => request.post(`/logistics/apply-eorder/batch-order`, data);

/**
 * 根据快递公司及运单号查物流轨迹
 */
export const getTraceByLogistic = ({ shipperCode, logisticNo }) =>
  request.get(`/logistics-trace/traces/by-no/${shipperCode}/${logisticNo}`);

/**
 * 根据快递公司查模板列表(菜鸟)
 */
export const getTemplateByCP = ({ cpCode }) => request.get(`/logistics-cn/get-template/by-code/${cpCode}`);

/**
 * 获取品牌商物流公司打印模版使用习惯(菜鸟)
 */
export const getLastUseTemplate = ({ cpCode }) => request.get(`/logistics-cn/get-habit/template/${cpCode}`);

/**
 * 记录品牌商物流公司打印模版本次使用习惯(菜鸟)
 */
export const setLastUseTemplate = (data) => request.post(`/logistics-cn/record-template/habit`, data);

/**
 * 根据喷品牌方查询开通物流轨迹业务情况
 */
export const logisticsTraceGetSubscription = (brandBusinessId) =>
  request.get(`/logistics-trace/get-subscription/${brandBusinessId}`);
