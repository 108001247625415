// utils
import persistedState from '@hjgy/common/persistedState';

import { namespace, getters as $getters, actions as $actions, mutations as $mutations } from './types';

/**
 * state
 */
const state = persistedState(
  namespace,
  {
    rewardSpan: null,
  },
  {
    // ...other
  },
);

/**
 * getters
 */
const getters = {
  [$getters.rewardSpan]: (state) => state.rewardSpan,
};

/**
 * actions
 */
const actions = {
  [$actions.setRewardSpan]({ commit }, { rewardSpan }) {
    commit($mutations.changeRewardSpan, { rewardSpan });
  },
};

/**
 * mutations
 */
const mutations = {
  [$mutations.changeRewardSpan](state, { rewardSpan }) {
    state.rewardSpan = rewardSpan;
  },
};

export default {
  namespaced: true,

  state,

  getters,

  actions,

  mutations,
};
