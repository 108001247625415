import sex from '@hjgy/common/filters/sex';
import auditProgress from '@hjgy/common/filters/auditProgress';
import auditStatus from '@hjgy/common/filters/auditStatus';
import authStatus from '@hjgy/common/filters/authStatus';
import userAuditProgress from '@hjgy/common/filters/userAuditProgress';

export default {
  sex,
  auditProgress,
  auditStatus,
  authStatus,
  userAuditProgress,
};
