<script>
export default {
  name: 'main-layout-title',
  components: {},
  props: {
    primary: Boolean,
  },
  methods: {},
  render() {
    return (
      <div class={['main-layout-title', { 'main-layout-title__primary': this.primary }]}>{this.$slots.default}</div>
    );
  },
};
</script>

<style lang="postcss">
@import '~@/styles/base/var.css';

.main-layout-title {
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-size: 14px;
  color: #666;

  &.main-layout-title__primary {
    padding-left: 20px;
    color: #000;
    font-size: 15px;
    line-height: 15px;

    &:before {
      margin-right: 7px;
      display: block;
      content: '';
      width: 3px;
      height: 15px;
      border-radius: 1.5px;
      background: var(--color-primary);
    }
  }
}
</style>
