<script>
import { Badge, MenuItem } from 'element-ui';

const El = { Badge, MenuItem };

export default {
  name: 'menu-layout-menu-item',
  functional: true,
  props: {
    index: String,
    route: Object,
    label: String,
    badge: {},
  },
  methods: {},
  render(h, context) {
    const data = {
      ...context.data,
      props: {
        index: context.props.index,
        route: context.props.route,
        label: context.props.label,
      },
    };

    return (
      <El.MenuItem {...data} class="menu-layout-menu-item">
        <div class="menu-layout-menu-item__context">
          <div class="menu-layout-menu-item__context-label">{context.slots().default}</div>
          {!!context.props.badge && <El.Badge class="menu-layout-menu-item__badge" value={context.props.badge} />}
        </div>
      </El.MenuItem>
    );
  },
};
</script>

<style lang="postcss">
@import '~@/styles/base/var.css';

.menu-layout-menu-item {
  height: 40px;
  line-height: 40px;
  padding-right: 10px;
  margin: 5px 0;

  & .menu-layout-menu-item__context {
    display: flex;

    & .menu-layout-menu-item__context-label {
      flex: 1;
    }

    & .menu-layout-menu-item__badge {
    }
  }

  &.is-active {
    /*background: rgba(242, 242, 242, 1);*/
    /*background-color: #E9EAF0;*/
    background-color: #f0f0f4;
    /*margin: 0 5px;*/
    border-radius: 4px;
  }
}

.menu-layout-menu-item:hover {
  background-color: #f0f0f4;
  /*margin: 0 5px;*/
  border-radius: 4px;
}
</style>
