<script>
export default {
  name: 'main-layout-footer',
  props: {},
  computed: {
    layout: {
      cache: false,
      get() {
        return findParent(this, 'main-layout');
      },
    },
  },
  methods: {},
  mounted() {
    if (this.$parent !== this.layout) {
      this.layout.setFooter(this.$el);
    }
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  },
  render() {
    return <div class="main-layout-footer">{this.$slots.default}</div>;
  },
};

function findParent(vm, name) {
  const parent = vm.$parent;

  if (!parent) return;

  if (parent.$options.name === name) {
    return parent;
  }

  return findParent(parent, name);
}
</script>

<style lang="postcss">
@import '~@/styles/base/var.css';

.main-layout-footer {
  position: relative;
  border-top: 1px solid #e4e7ed;
  background: #fff;
  padding: 10px;
}
</style>
