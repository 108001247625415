import request from '@hjgy/common/request';

/**
 * 登录
 */
export const login = (data) => request.post('/account/login/pc', data);

/**
 * 登出
 */
export const logout = (data) => request.get('/account/logout', data);

/**
 * 获取当前账号用户信息
 */
export const getCurrentUserInfo = () => request.get('/account/current-user-info');

/**
 * 查询后台账号列表
 */
export const searchList = (params) => request.get('/account/list', { params });

/**
 * 查询用户账号信息
 */
export const getUserInfo = (userId) => request.get(`/account/info/${userId}`);

/**
 * 修改自己密码
 */
export const changeMyPwd = (data) => request.put('/account/update-mypassword', data);

/**
 * 修改品牌商用户名和密码
 */
export const changeOtherPwd = (data) => request.put('/account/update-userpassword', data);

/**
 * 查询用户账号信息
 */
export const accountInfo = (userId) => request.get(`/account/info/${userId}`);
