<script>
import { Menu } from 'element-ui';
import MenuLayoutMenuItem from './MenuLayoutMenuItem.vue';

const El = { Menu };

export default {
  name: 'menu-layout-menu',
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: null,
    };
  },
  computed: {},
  methods: {
    updateActive() {
      let active = this.menus.find((menu) => {
        menu = formatMenu(menu);
        if (!menu.route) return false;
        return this.$route.path.indexOf(menu.route.path) === 0;
      });

      active = formatMenu(active);

      if (active) {
        this.activeIndex = active.index;
        return;
      }

      if (this.menus[0]) {
        const firstMenu = formatMenu(this.menus[0]);
        this.activeIndex = firstMenu.index;
        this.$router.replace(firstMenu.route.path);
      }
    },
  },
  watch: {
    '$route.path'() {
      this.updateActive();
    },
  },
  created() {
    this.updateActive();
  },
  render() {
    return (
      <div class="menu-layout-menu">
        <El.Menu class="menu-layout-menu__el-menu" router defaultActive={this.activeIndex}>
          {this.$slots.default ||
            this.menus.map((menu) => {
              menu = formatMenu(menu);
              return (
                <MenuLayoutMenuItem index={menu.index} route={menu.route} badge={menu.badge}>
                  {menu.label}
                </MenuLayoutMenuItem>
              );
            })}
        </El.Menu>
      </div>
    );
  },
};

function formatRoute(route) {
  if (typeof route === 'string') {
    return { path: route };
  }
  return route;
}

function formatMenu(menu) {
  if (!menu) return menu;
  const route = formatRoute(menu.route);
  return {
    ...menu,
    index: menu.index || route.path,
    route,
  };
}
</script>

<style lang="postcss">
@import '~@/styles/base/var.css';

.menu-layout-menu {
  padding-top: 40px;
  background-color: #fafafa;

  & .menu-layout-menu__el-menu {
    border: none;
    background-color: #fafafa;
    margin: 0 5px;
  }
}
</style>
