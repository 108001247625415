export default {
  c: {
    yibi: '亿币',
    'server-error': '服务器繁忙',
  },
  customize: {
    agent_expand: '发展',
    agent_recommend: '推荐',
    order_goods_amount: '货款',
    order_profit: '利润',
    rebate: '奖金',
    rebate_invite: '邀请后续奖',
    rebate_invite_first: '邀请首次奖',
    rebate_manage: '管理奖',
    rebate_manage_first: '管理首次奖',
    rebate_manage_later: '管理后续奖',
    rebate_recommend: '推荐奖',
    rebate_recommend_first: '推荐首次奖',
    rebate_recommend_later: '推荐后续奖',
    rebate_sales: '业绩奖金',
    rebate_upgrade_first: '升级首次奖',
    rebate_upgrade: '升级奖',
    rebate_upgrade_later: '升级后续奖',
    rebate_reward_first: '首次奖',
    rebate_reward_later: '后续奖',
    material: '素材中心',
    agent: '代理',
    superior: '上级',
    lower: '下级',
    security_deposit: '保证金',
    team: '团队',
  },
};
