<script>
import Title from './Title.vue';

export default {
  name: 'main-layout-header',
  components: {},
  props: {
    title: {},
    primary: Boolean,
  },
  methods: {
    renderTitle() {
      if (this.$slots.default) return this.$slots.default;

      if (this.title) {
        return <Title primary={this.primary}>{this.title}</Title>;
      }
    },
    renderRight() {
      if (this.$slots.right) return;

      return <div>{this.$slots.right}</div>;
    },
  },
  render() {
    return (
      <div class="main-layout-header">
        {this.renderTitle()}

        {this.renderRight()}
      </div>
    );
  },
};
</script>

<style lang="postcss">
@import '~@/styles/base/var.css';

.main-layout-header {
  display: flex;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid #e4e7ed;
  height: 40px;
  overflow-x: auto;
}
</style>
