<template>
  <router-view></router-view>
</template>

<script>
import './styles/iconfont/iconfont.css';
export default {
  name: 'app',
};
</script>

<style lang="postcss">
@import './styles/global.css';
</style>
