import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createI18n } from '@hjgy/common/i18n';

Vue.use(VueI18n);
// 默认（好几个亿）

let messages = {
  'zh-cn': require('./messages/zh-cn').default,
};

const i18n = createI18n({
  locale: 'zh-cn',
  messages,
  silentTranslationWarn: true,
});

export const $t = function (key, ...values) {
  return i18n._t(key, i18n.locale, i18n._getMessages(), this, ...values);
};

export default i18n;
