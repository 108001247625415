<script>
import { BreadcrumbItem } from 'element-ui';

const El = { BreadcrumbItem };

export default {
  name: 'main-layout-breadcrumb-item',
  functional: true,
  props: {
    to: {},
    replace: {},
    back: Boolean,
  },
  render(h, context) {
    const data = {
      ...context.data,
      props: context.props,
      nativeOn: {
        click: () => {
          if (context.props.back) {
            const router = context.parent.$router;

            router && router.back();
          }
        },
      },
    };
    return (
      <El.BreadcrumbItem {...data} class="main-layout-breadcrumb-item">
        {context.slots().default}
      </El.BreadcrumbItem>
    );
  },
};
</script>

<style lang="postcss">
@import '~@/styles/base/var.css';

.main-layout-breadcrumb-item {
  & .el-breadcrumb__inner {
    font-weight: normal;
    /*color: #409EFF;*/
    color: var(--color-primary);
    cursor: pointer;
  }
}
</style>
