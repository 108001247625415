// apis
import request from '@hjgy/common/request';
import * as accountApi from '@/apis/accountApi';
import * as brandBusinessSettingApi from '@/apis/brandBusinessSettingApi';
import * as customizeApi from '@/apis/customizeApi';
import * as pointsConfigApi from '@/apis/pointsConfigApi';
// utils
import persistedState from '@hjgy/common/persistedState';
import md5 from 'md5';
import i18n from '@/i18n';

import { namespace, getters as $getters, actions as $actions, mutations as $mutations } from './types';
// constant
import BrandBusinessSetting from '@hjgy/common/constant/BrandBusinessSetting';
import customizeSetting from '@hjgy/common/constant/customizeSetting';
import PointsSetting from '@hjgy/common/constant/points/PointsSetting';
import * as operatePasswordApi from '@/apis/operatePasswordApi';
import { Message } from 'element-ui';

/**
 * state
 */
const state = persistedState(
  namespace,
  {
    loginInfo: {},
    loginSession: null,
  },
  {
    currentUser: null,
    brandBusiness: null,
    useCentralBankModel: null, // 是否使用央行亿币充值模式：0.否,1.是
    shopThirdSend: undefined,
    shopThirdWarehouse: undefined,
    enablePoints: null,
    enableCrowdWarehouse: null, // 开启多仓库
    thirdPayWay: null, // 第三方支付方式
    isJoinPay: false, // 是否汇聚支付
    enableOccupy: null, // 非公司发货（代理）冻结库存
    securityPassword: {
      //安全密码设置项
      pageSwitch: 0,
      type: 1,
      adminConfig: [
        { code: 'AUTH_AUDIT', adminRole: 0 },
        { code: 'UPGRADE_AUDIT', adminRole: 0 },
        { code: 'RECHARGE_AUDIT', adminRole: 0 },
        { code: 'CASH_AUDIT', adminRole: 0 },
        { code: 'AWARD_AUDIT', adminRole: 0 },
        { code: 'AWARD_EXCHANGE', adminRole: 0 },
        { code: 'AGENT_INFO', adminRole: 0 },
        { code: 'AGENT_CREATE', adminRole: 0 },
        { code: 'CENTRAL_BANK_WALLET', adminRole: 0 },
        { code: 'PAY_ONLINE_RECORD', adminRole: 0 },
        { code: 'MALL_ORDER', adminRole: 0 },
        { code: 'MALL_AUDIT', adminRole: 0 },
      ],
      agentConfig: [
        { code: 'AUTH_AUDIT', agentRole: 0 },
        { code: 'UPGRADE_AUDIT', agentRole: 0 },
        { code: 'AGENT_MY_WAllET', agentRole: 0 },
        { code: 'AGENT_INFO', agentRole: 0 },
        { code: 'RECHARGE_AUDIT', agentRole: 0 },
        { code: 'AGENT_MY_AWARD', agentRole: 0 },
        { code: 'AGENT_ORDER_PAY', agentRole: 0 },
        { code: 'AGENT_CHANGE_ORDER_PAY', agentRole: 0 },
        { code: 'VIP_AUDIT', agentRole: 0 },
        { code: 'MALL_ORDER_PAY', agentRole: 0 },
      ],
      vipConfig: [
        { code: 'MALL_ORDER_PAY', vipRole: 0, title: '下单支付', tips: '(商城余额支付、货款支付、积分支付）' },
      ],
    },
  },
);
if (state.loginSession) {
  request.setLoginSession(state.loginSession);
}

/**
 * getters
 */
const getters = {
  [$getters.loginInfo]: (state) => state.loginInfo,
  [$getters.loginSession]: (state) => state.loginSession,
  [$getters.currentUser]: (state) => state.currentUser,
  [$getters.brandBusiness]: (state) => state.brandBusiness,
  [$getters.useCentralBankModel]: (state) => state.brandBusiness.useCentralBankModel,
  [$getters.isCenterCloudModel]: ({ brandBusiness }) =>
    brandBusiness?.useCentralBankModel && brandBusiness?.warehouseType === 2,
  [$getters.shopThirdSend]: (state) => state.shopThirdSend,
  [$getters.userThirdSend]: (state) => state.userThirdSend,
  [$getters.enablePoints]: (state) => state.enablePoints,
  [$getters.enableWareHouseInout]: (state) => state.enableWareHouseInout,
  [$getters.enableCrowdWarehouse]: (state) => state.enableCrowdWarehouse,
  [$getters.shopThirdWarehouse]: (state) => state.shopThirdWarehouse,
  [$getters.userThirdWarehouse]: (state) => state.userThirdWarehouse,
  [$getters.securityPassword]: (state) => state.securityPassword,
  [$getters.thirdPayWay]: (state) => state.thirdPayWay,
  [$getters.isJoinPay]: (state) => state.isJoinPay,
  [$getters.enableOccupy]: (state) => state.enableOccupy,
};

/**
 * actions
 */
const actions = {
  [$actions.setLoginSession]: async ({ commit }, loginSession) => {
    // 设置token
    request.setLoginSession(loginSession);

    commit($mutations.loginSuccess, { loginInfo: {}, loginSession });

    return loginSession;
  },
  [$actions.login]: async ({ commit }, { userName, password, isRemember }) => {
    // api
    const resp = await accountApi.login({
      userName,
      password: md5(password),
      isRemember: isRemember,
    });

    const { loginSession } = resp;

    // 设置token
    request.setLoginSession(loginSession);

    commit($mutations.loginSuccess, { loginInfo: { userName }, loginSession });

    return resp;
  },

  [$actions.logout]: async ({ commit }) => {
    await accountApi.logout().catch((e) => {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });

    request.setLoginSession(null);

    commit($mutations.logoutSuccess);
  },

  [$actions.setBrandBusiness]: async ({ commit }, { brandBusiness }) => {
    commit($mutations.setBrandBusiness, brandBusiness);
  },

  [$actions.getCurrentUser]: async ({ commit, dispatch }) => {
    const resp = await accountApi.getCurrentUserInfo().catch((e) => e);

    const { errCode, user, brandBusiness, loginSession } = resp;

    if (errCode) throw { errCode };

    // 设置系统设置
    Promise.all([
      dispatch($actions.setSystemSetting),
      dispatch($actions.setCustomizeSetting, brandBusiness),
      dispatch($actions.setSecurityPasswordConfig),
    ]);

    if (!errCode) {
      commit($mutations.getCurrentUserSuccess, { loginSession, userInfo: user, brandBusiness });
    }

    return resp;
  },

  [$actions.setSystemSetting]: async ({ commit }) => {
    const codes = [
      BrandBusinessSetting.ScoreUnit.value, // 亿币文案
      BrandBusinessSetting.AllowedThirdSendGoods.value,
      BrandBusinessSetting.PointsEnable.value,
      BrandBusinessSetting.WareHouseSystemInout.value,
      BrandBusinessSetting.EnableCrowdWarehouse.value, // 开启多仓库设置
      BrandBusinessSetting.UseThirdWarehouse.value, // 开启第三方仓库
      BrandBusinessSetting.ThirdPayWay.value, // 第三方支付
    ];
    const { brandBusinessSettings } = await brandBusinessSettingApi.getSettingsByCodes({ codes }).catch((e) => e);
    const pointsConfig = await pointsConfigApi
      .getPointsConfig({ codes: [PointsSetting.PointsName.value] })
      .catch((e) => e);
    if (brandBusinessSettings) {
      await Promise.all(
        brandBusinessSettings.map(async (brandBusinessSetting) => {
          if (brandBusinessSetting.code === BrandBusinessSetting.ScoreUnit.value) {
            // 设置多语言文本
            const yibiName = brandBusinessSetting ? brandBusinessSetting.value : '';
            if (yibiName) {
              // console.log('i18n', i18n)
              await i18n.setLocaleMessage('zh-cn', { ...i18n.messages['zh-cn'], c: { yibi: yibiName } });
            }
          }

          if (brandBusinessSetting.code === BrandBusinessSetting.AllowedThirdSendGoods.value) {
            // 设置代理系统是否允许第三方发货
            commit($mutations.setUserThirdSend, !!(brandBusinessSetting.value - 0));
          } else if (brandBusinessSetting.code === BrandBusinessSetting.UseThirdWarehouse.value) {
            // 设置代理系统是否使用第三方仓库
            commit($mutations.setUserThirdWarehouse, !!(brandBusinessSetting.value - 0));
          } else if (brandBusinessSetting.code === BrandBusinessSetting.PointsEnable.value) {
            // 是否启动积分功能
            commit($mutations.setEnablePoints, !!(brandBusinessSetting.value - 0));
          } else if (brandBusinessSetting.code === BrandBusinessSetting.WareHouseSystemInout.value) {
            // 是否启动第三方出入库
            commit($mutations.setWareHouseInout, !!(brandBusinessSetting.value - 0));
          } else if (brandBusinessSetting.code === BrandBusinessSetting.EnableCrowdWarehouse.value) {
            // 是否开启多仓库
            commit($mutations.setCrowdWarehouse, !!(brandBusinessSetting.value - 0));
          } else if (brandBusinessSetting.code === BrandBusinessSetting.ThirdPayWay.value) {
            // 第三方支付
            if (brandBusinessSetting.value === 'JOINPAY') commit($mutations.setIsJoinPay, true);
            commit($mutations.setThirdPayWay, brandBusinessSetting.value);
          }
        }),
      );
    }
    const messages = i18n.messages['zh-cn'];
    const points = pointsConfig && pointsConfig.list && pointsConfig.list.length ? pointsConfig.list[0].value : '积分';
    await i18n.setLocaleMessage('zh-cn', { ...messages, c: { ...messages.c, points: points } });
  },
  // 设置自定义文案
  [$actions.setCustomizeSetting]: async ({ commit }, brandBusiness) => {
    if (!commit || !brandBusiness) return;
    const params = {
      brandBusinessId: brandBusiness.id,
    };
    const customizeSettings = await customizeApi.getCustomizeList(params).catch((e) => e);
    if (customizeSettings) {
      const list = customizeSettings.reduce(
        (map, item) => ({
          ...map,
          [item.code]: item,
        }),
        {},
      );
      await i18n.setLocaleMessage('zh-cn', {
        ...i18n.messages['zh-cn'],
        customize: {
          agent_expand: list[customizeSetting.AgentExpand.value].value,
          agent_recommend: list[customizeSetting.AgentRecommend.value].value,
          order_goods_amount: list[customizeSetting.OrderGoodsAmount.value].value,
          order_profit: list[customizeSetting.OrderProfit.value].value,
          rebate: list[customizeSetting.Rebate.value].value,
          rebate_invite: list[customizeSetting.RebateInvite.value].value,
          rebate_invite_first: list[customizeSetting.RebateInviteFirst.value].value,
          rebate_manage: list[customizeSetting.RebateManage.value].value,
          rebate_manage_first: list[customizeSetting.RebateManageFirst.value].value,
          rebate_manage_later: list[customizeSetting.RebateManageLater.value].value,
          rebate_recommend: list[customizeSetting.RebateRecommend.value].value,
          rebate_recommend_first: list[customizeSetting.RebateRecommendFirst.value].value,
          rebate_recommend_later: list[customizeSetting.RebateRecommendLater.value].value,
          rebate_sales: list[customizeSetting.RebateSales.value].value,
          rebate_upgrade_first: list[customizeSetting.RebateUpgradeFirst.value].value,
          rebate_upgrade: list[customizeSetting.RebateUpgrade.value].value,
          rebate_upgrade_later: list[customizeSetting.RebateUpgradeLater.value].value,
          rebate_reward_first: list[customizeSetting.RebateRewardFirst.value].value,
          rebate_reward_later: list[customizeSetting.RebateRewardLater.value].value,
          rebate_share: list[customizeSetting.RebateShare.value].value,
          material: list[customizeSetting.Material.value].value,
          agent: list[customizeSetting.Agent.value].value,
          superior: list[customizeSetting.Superior.value].value,
          lower: list[customizeSetting.Lower.value].value,
          security_deposit: list[customizeSetting.Deposit.value].value,
          team: list[customizeSetting.Team.value].value,
        },
      });
    }
  },

  [$actions.setShopThirdSend]: async ({ commit }, shopThirdSend) => {
    commit($mutations.setShopThirdSend, shopThirdSend);
  },

  [$actions.setShopThirdWarehouse]: async ({ commit }, shopThirdWarehouse) => {
    commit($mutations.setShopThirdWarehouse, shopThirdWarehouse);
  },

  [$actions.setAll]: async ({ commit }, { all0 }) => {
    const all = {
      pageSwitch: all0.pageSwitch ? 1 : 0,
      type: all0.type ? 1 : 2,
    };

    const { errCode, errMsg } = await operatePasswordApi.batchUpdatePwdPageSetting(all).catch((e) => e);
    if (errCode) {
      Message.error(errMsg);
    } else {
      commit($mutations.changeAll, { all });
      Message.success('修改成功');
    }
  },

  [$actions.setSecurityPasswordConfig]: async ({ commit }) => {
    const operatePasswordconfig = await operatePasswordApi.getUserPwdPageSettingList().catch((e) => e);
    if (operatePasswordconfig.errCode) return;
    commit($mutations.changeSecurityPasswordConfig, { operatePasswordconfig });
  },

  [$actions.setConfig]: async ({ commit }, { obj, code, value }) => {
    const pwdPageSetting = {
      code: code,
      agentRole: 0,
      adminRole: 0,
      vipRole: 0,
    };
    if (obj === 1) {
      state.securityPassword.agentConfig.forEach((val) => {
        if (val.code === code) pwdPageSetting.agentRole = val.agentRole;
      });
      state.securityPassword.vipConfig.forEach((val) => {
        if (val.code === code) pwdPageSetting.vipRole = val.vipRole;
      });
      pwdPageSetting.adminRole = value ? 1 : 0;
    } else if (obj === 2) {
      state.securityPassword.adminConfig.forEach((val) => {
        if (val.code === code) pwdPageSetting.adminRole = val.adminRole;
      });
      state.securityPassword.vipConfig.forEach((val) => {
        if (val.code === code) pwdPageSetting.vipRole = val.vipRole;
      });
      pwdPageSetting.agentRole = value ? 1 : 0;
    } else if (obj === 3) {
      state.securityPassword.adminConfig.forEach((val) => {
        if (val.code === code) pwdPageSetting.adminRole = val.adminRole;
      });
      state.securityPassword.agentConfig.forEach((val) => {
        if (val.code === code) pwdPageSetting.agentRole = val.agentRole;
      });
      pwdPageSetting.vipRole = value ? 1 : 0;
    }
    const { errCode, errMsg } = await operatePasswordApi.updateUserPwdPageSetting(pwdPageSetting).catch((e) => e);
    if (errCode) {
      Message.error(errMsg);
    } else {
      commit($mutations.changeUpConfig, { obj, code, value });
      Message.success('修改成功');
    }
  },

  [$actions.setOccupy]: async ({ commit }, enableOccupy) => {
    commit($mutations.changeOccupy, enableOccupy);
  },
};

/**
 * mutations
 */
const mutations = {
  [$mutations.loginSuccess](state, { loginInfo, loginSession }) {
    // 将password去掉
    loginInfo = { ...loginInfo };
    delete loginInfo.password;
    state.loginSession = loginSession;
  },

  [$mutations.logoutSuccess](state) {
    state.currentUser = null;
    state.loginSession = null;
  },

  [$mutations.setBrandBusiness](state, brandBusiness) {
    state.brandBusiness = brandBusiness;
  },

  [$mutations.getCurrentUserSuccess](state, { loginSession, userInfo, brandBusiness }) {
    state.currentUser = userInfo;
    state.brandBusiness = brandBusiness;
    state.loginSession = loginSession;
  },

  [$mutations.setShopThirdSend](state, shopThirdSend) {
    state.shopThirdSend = shopThirdSend;
  },

  [$mutations.setShopThirdWarehouse](state, shopThirdWarehouse) {
    state.shopThirdWarehouse = shopThirdWarehouse;
  },

  [$mutations.setUserThirdWarehouse](state, userThirdWarehouse) {
    state.userThirdWarehouse = userThirdWarehouse;
  },

  [$mutations.setUserThirdSend](state, userThirdSend) {
    state.userThirdSend = userThirdSend;
  },

  [$mutations.setEnablePoints](state, enablePoints) {
    state.enablePoints = enablePoints;
  },

  [$mutations.setWareHouseInout](state, enableWareHouseInout) {
    state.enableWareHouseInout = enableWareHouseInout;
  },

  [$mutations.setCrowdWarehouse](state, enableCrowdWarehouse) {
    state.enableCrowdWarehouse = enableCrowdWarehouse;
  },

  [$mutations.setThirdPayWay](state, thirdPayWay) {
    state.thirdPayWay = thirdPayWay;
  },

  [$mutations.setIsJoinPay](state, isJoinPay) {
    state.isJoinPay = isJoinPay;
  },

  [$mutations.changeOccupy](state, enableOccupy) {
    state.enableOccupy = enableOccupy;
  },

  [$mutations.changeAll](state, { all }) {
    state.securityPassword.pageSwitch = all.pageSwitch;
    state.securityPassword.type = all.type;
    if (state.securityPassword.type === 1) {
      state.securityPassword.adminConfig.forEach((val) => {
        val.adminRole = state.securityPassword.pageSwitch;
      });
    } else if (state.securityPassword.type === 2) {
      state.securityPassword.agentConfig.forEach((val) => {
        val.agentRole = state.securityPassword.pageSwitch;
      });
    } else if (state.securityPassword.type === 3) {
      state.securityPassword.vipConfig.forEach((val) => {
        val.vipRole = state.securityPassword.pageSwitch;
      });
    }
  },

  [$mutations.changeSecurityPasswordConfig](state, { operatePasswordconfig }) {
    const config = new Map();
    if (operatePasswordconfig.length) {
      operatePasswordconfig.forEach((item) => {
        config.set(item.code, item);
      });
    }
    state.securityPassword.adminConfig.forEach((val) => {
      if (config.get(val.code)) val.adminRole = config.get(val.code).adminRole;
    });
    state.securityPassword.agentConfig.forEach((val) => {
      if (config.get(val.code)) val.agentRole = config.get(val.code).agentRole;
    });
    state.securityPassword.vipConfig.forEach((val) => {
      if (config.get(val.code)) val.vipRole = config.get(val.code).vipRole;
    });
  },

  [$mutations.changeUpConfig](state, { obj, code, value }) {
    if (obj === 1) {
      state.securityPassword.adminConfig.forEach((val) => {
        if (val.code === code) val.adminRole = value ? 1 : 0;
      });
    } else if (obj === 2) {
      state.securityPassword.agentConfig.forEach((val) => {
        if (val.code === code) val.agentRole = value ? 1 : 0;
      });
    } else if (obj === 3) {
      state.securityPassword.vipConfig.forEach((val) => {
        if (val.code === code) val.vipRole = value ? 1 : 0;
      });
    }
  },
};

export default {
  namespaced: true,

  state,

  getters,

  actions,

  mutations,
};
