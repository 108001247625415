import BlankView from './BlankView.vue';

// 配置路由
const routes = [
  {
    path: '/login',
    component: () => import('@/views/login/view.vue'),
    meta: { notLogin: true },
  },
  {
    path: '/',
    component: () => import('@/views/home/view.vue'),
    children: [
      { path: 'workbench', component: () => import('@/views/home/children/workbench/view.vue') },
      {
        path: 'user',
        component: () => import('@/views/home/children/user/view.vue'),
        children: [
          { path: '', redirect: 'user/list' },
          { path: 'audit/list', component: () => import('@/views/home/children/user/children/audit/list.vue') },
          { path: 'user/list', component: () => import('@/views/home/children/user/children/user/list.vue') },
          { path: 'role/list', component: () => import('@/views/home/children/user/children/role/list.vue') },
          {
            path: 'audit/list/audit/detail/:id',
            component: () => import('@/views/home/children/user/children/audit/detail.vue'),
          },
          {
            path: 'user/list/user/detail/:id',
            component: () => import('@/views/home/children/user/children/user/detail.vue'),
          },
          {
            path: 'notice/setting',
            component: () => import('@/views/home/children/user/children/notice/setting.vue'),
          },
          {
            path: 'operationLog/list',
            component: () => import('@/views/home/children/user/children/operationLog/list.vue'),
          },
        ],
      },
      {
        path: 'user',
        component: BlankView,
        children: [
          {
            path: 'audit/detail/:id',
            component: () => import('@/views/home/children/user/children/audit/detail.vue'),
          },
          { path: 'user/detail/:id', component: () => import('@/views/home/children/user/children/user/detail.vue') },
        ],
      },
      // 审核管理
      {
        path: 'auth',
        component: () => import('@/views/home/children/auth/view.vue'),
        children: [
          { path: 'authAudit', component: () => import('@/views/home/children/auth/children/authAudit/list.vue') },
          {
            path: 'authAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/authAudit/detail.vue'),
          },
          {
            path: 'authAudit/export',
            component: () => import('@/views/home/children/auth/children/authAudit/export.vue'),
          },
          {
            path: 'upgradeAudit',
            component: () => import('@/views/home/children/auth/children/upgradeAudit/list.vue'),
          },
          {
            path: 'upgradeAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/upgradeAudit/detail.vue'),
          },
          {
            path: 'upgradeAudit/export',
            component: () => import('@/views/home/children/auth/children/upgradeAudit/export.vue'),
          },
          {
            path: 'rechargeAudit',
            component: () => import('@/views/home/children/auth/children/rechargeAudit/list.vue'),
          },
          {
            path: 'rechargeAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/rechargeAudit/detail.vue'),
          },
          {
            path: 'rechargeAudit/export',
            component: () => import('@/views/home/children/auth/children/rechargeAudit/export.vue'),
          },
          {
            path: 'withdrawAudit',
            component: () => import('@/views/home/children/auth/children/withdrawAudit/list.vue'),
          },
          {
            path: 'withdrawAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/withdrawAudit/detail.vue'),
          },
          {
            path: 'withdrawAudit/export',
            component: () => import('@/views/home/children/auth/children/withdrawAudit/export.vue'),
          },
          { path: 'assessment', component: () => import('@/views/home/children/auth/children/assessment/view.vue') },
          { path: 'bonusAudit', component: () => import('@/views/home/children/auth/children/bonusAudit/list.vue') },
          {
            path: 'bonusAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/bonusAudit/detail.vue'),
          },
          {
            path: 'bonusAudit/export',
            component: () => import('@/views/home/children/auth/children/bonusAudit/export.vue'),
          },
          {
            path: 'renewalAudit',
            component: () => import('@/views/home/children/auth/children/renewalAudit/list.vue'),
          },
          {
            path: 'renewalAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/renewalAudit/detail.vue'),
          },
          { path: 'points', component: () => import('@/views/home/children/auth/children/points/list.vue') },
          {
            path: 'points/detail/:id',
            component: () => import('@/views/home/children/auth/children/points/detail.vue'),
          },
          { path: 'points/export', component: () => import('@/views/home/children/auth/children/points/export.vue') },
          {
            path: 'exchangeAudit',
            component: () => import('@/views/home/children/auth/children/exchangeAudit/list.vue'),
          },
          {
            path: 'exchangeAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/exchangeAudit/detail.vue'),
          },
          {
            path: 'exchangeAudit/export',
            component: () => import('@/views/home/children/auth/children/exchangeAudit/export.vue'),
          },
          {
            path: 'personalAudit',
            component: () => import('@/views/home/children/auth/children/personalAudit/list.vue'),
          },
          {
            path: 'personalAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/personalAudit/detail.vue'),
          },
          {
            path: 'agentShopAudit',
            component: () => import('@/views/home/children/auth/children/agentShop/list.vue'),
          },
          {
            path: 'agentShopAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/agentShop/detail.vue'),
          },
        ],
      },
      {
        path: 'auth',
        component: BlankView,
        children: [
          {
            path: 'authAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/authAudit/detail.vue'),
          },
          {
            path: 'upgradeAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/upgradeAudit/detail.vue'),
          },
          {
            path: 'rechargeAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/rechargeAudit/detail.vue'),
          },
          {
            path: 'withdrawAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/withdrawAudit/detail.vue'),
          },
          {
            path: 'bonusAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/bonusAudit/detail.vue'),
          },
          {
            path: 'renewalAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/renewalAudit/detail.vue'),
          },
          {
            path: 'exchangeAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/exchangeAudit/detail.vue'),
          },
          {
            path: 'personalAudit/detail/:id',
            component: () => import('@/views/home/children/auth/children/personalAudit/detail.vue'),
          },
        ],
      },
      {
        path: 'team',
        component: () => import('@/views/home/children/team/view.vue'),
        children: [
          { path: 'list', component: () => import('@/views/home/children/team/children/list/view.vue') },
          {
            path: 'list/agentList/:id',
            component: () => import('@/views/home/children/team/children/agentList/view.vue'),
          },
          { path: 'activity', component: () => import('@/views/home/children/team/children/activity/view.vue') },
          {
            path: 'list/rechargeDetail/:userId',
            component: () => import('@/views/home/children/recharge/children/agent/recharge.vue'),
          },
          {
            path: 'list/deductDetail/:userId',
            component: () => import('@/views/home/children/recharge/children/agent/deduct.vue'),
          },
        ],
      },
      // 系统管理
      {
        path: 'system',
        component: () => import('@/views/home/children/system/view.vue'),
        children: [
          { path: 'bindWeixin', component: () => import('@/views/home/children/system/children/bindWeixin/view.vue') },
          { path: 'weixin', component: () => import('@/views/home/children/system/children/weixin/view.vue') },
          { path: 'agentLevel', component: () => import('@/views/home/children/system/children/agentLevel/view.vue') },
          { path: 'authSet', component: () => import('@/views/home/children/system/children/auth/view.vue') },
          { path: 'sysSet', component: () => import('@/views/home/children/system/children/sysSet/view.vue') },
          {
            path: 'upgrade',
            component: () => import('@/views/home/children/system/children/upgrade/view.vue'),
            children: [
              { path: '', redirect: 'base' },
              { path: 'base', component: () => import('@/views/home/children/system/children/upgrade/base.vue') },
              {
                path: 'condition',
                component: () => import('@/views/home/children/system/children/upgrade/condition.vue'),
              },
            ],
          },
          {
            path: 'customizePage',
            component: () => import('@/views/home/children/system/children/customizePage/view.vue'),
            children: [
              { path: '', redirect: 'workbench' },
              {
                path: 'workbench',
                component: () => import('@/views/home/children/system/children/customizePage/components/workbench.vue'),
              },
              {
                path: 'home',
                component: () => import('@/views/home/children/system/children/weChatHomeView/view.vue'),
              },
            ],
          },
          {
            path: 'securityView',
            component: () => import('@/views/home/children/system/children/securityView/view.vue'),
            children: [
              { path: '', redirect: 'setting' },
              {
                path: 'setting',
                component: () => import('@/views/home/children/system/children/securityView/components/setting.vue'),
              },
              {
                path: 'normalModel',
                component: () => import('@/views/home/children/system/children/securityView/components/normal.vue'),
              },
              {
                path: 'banModel',
                component: () => import('@/views/home/children/system/children/securityView/components/ban.vue'),
              },
            ],
          },
          { path: 'orderSet', component: () => import('@/views/home/children/system/children/orderSet/view.vue') },
          { path: 'honor', component: () => import('@/views/home/children/system/children/honor/view.vue') },
          {
            path: 'authorize',
            component: () => import('@/views/home/children/system/children/authorize/view.vue'),
            children: [
              { path: '', redirect: 'auth' },
              { path: 'applySet', component: () => import('@/views/home/children/system/children/applySet/view.vue') },
              {
                path: 'auth',
                component: () => import('@/views/home/children/system/children/authorize/components/bash.vue'),
              },
              {
                path: 'disclaimer',
                component: () => import('@/views/home/children/system/children/disclaimer/view.vue'),
              },
              {
                path: 'unAuthorizedView',
                component: () => import('@/views/home/children/system/children/authorizedView/view.vue'),
              },
              {
                path: 'certificateTemplate',
                component: () => import('@/views/home/children/system/children/certificateTemplate/view.vue'),
              },
              {
                path: 'customCertificateTemplate',
                component: () => import('@/views/home/children/system/children/certificateTemplateCustom/view.vue'),
              },
              {
                path: 'inviteAgents',
                component: () => import('@/views/home/children/system/children/inviteAgents/view.vue'),
              },
              {
                path: 'activatingAgent',
                component: () => import('@/views/home/children/system/children/authorize/children/activatingAgent.vue'),
              },
            ],
          },
          {
            path: 'points',
            component: () => import('@/views/home/children/system/children/points/view.vue'),
            children: [
              {
                path: 'source',
                component: () => import('@/views/home/children/system/children/points/source/view.vue'),
                children: [
                  {
                    path: 'formSale',
                    component: () => import('@/views/home/children/system/children/points/source/sale.vue'),
                  },
                  {
                    path: 'formArea',
                    component: () => import('@/views/home/children/system/children/points/source/area.vue'),
                  },
                ],
              },
              {
                path: 'useSetting',
                component: () => import('@/views/home/children/system/children/points/useSetting/convert.vue'),
              },
              { path: 'setting', component: () => import('@/views/home/children/system/children/points/setting.vue') },
            ],
          },
          {
            path: 'securityPassword',
            component: () => import('@/views/home/children/system/children/securityPassword/view.vue'),
            children: [
              {
                path: 'setting',
                component: () => import('@/views/home/children/system/children/securityPassword/setting.vue'),
              },
            ],
          },
          {
            path: 'openPlatform',
            component: () => import('@/views/home/children/system/children/openPlatform/view.vue'),
            children: [
              { path: '', redirect: 'keyManage' },
              {
                path: 'keyManage',
                component: () => import('@/views/home/children/system/children/keyManage/view.vue'),
              },
              { path: 'callback', component: () => import('@/views/home/children/system/children/callback/view.vue') },
            ],
          },
        ],
      },
      {
        path: 'product',
        component: () => import('@/views/home/children/product/view.vue'),
        children: [
          {
            path: 'manage',
            component: () => import('@/views/home/children/product/children/productManage/view.vue'),
            children: [
              { path: '', redirect: 'list' },
              {
                path: 'list',
                component: () => import('@/views/home/children/product/children/productManage/list/view.vue'),
              },
              {
                path: 'close',
                name: 'close',
                component: () => import('@/views/home/children/product/children/productManage/list/view.vue'),
              },
              {
                path: 'stock/early/warning',
                component: () =>
                  import('@/views/home/children/product/children/productManage/StockEarlyWarning/view.vue'),
              },
            ],
          },
          {
            path: 'type',
            component: () => import('@/views/home/children/product/children/type/view.vue'),
            children: [
              { path: '', redirect: 'list' },
              { path: 'list', component: () => import('@/views/home/children/product/children/type/list/view.vue') },
            ],
          },
          {
            path: 'brand',
            component: () => import('@/views/home/children/product/children/brand/view.vue'),
            children: [
              { path: '', redirect: 'list' },
              { path: 'list', component: () => import('@/views/home/children/product/children/brand/list/view.vue') },
            ],
          },
          { path: 'homePage', component: () => import('@/views/home/children/product/children/homePage/view.vue') },
          {
            path: 'autoTemplate',
            component: () => import('@/views/home/children/product/children/autoTemplate/view.vue'),
          },
          {
            path: 'autoTemplate/edit',
            component: () => import('@/views/home/children/product/children/autoTemplate/edit.vue'),
          },
          {
            path: 'brand/create',
            component: () => import('@/views/home/children/product/children/brand/create/view.vue'),
          },
          {
            path: 'type/create',
            component: () => import('@/views/home/children/product/children/type/create/view.vue'),
          },
          {
            path: 'manage/edit/:id',
            component: () => import('@/views/home/children/product/children/productManage/edit/newView.vue'),
          },
          {
            path: 'manage/info/:id',
            component: () => import('@/views/home/children/product/children/productManage/info/view.vue'),
          },
          {
            path: 'manage/export',
            component: () => import('@/views/home/children/product/children/productManage/list/export.vue'),
          },
        ],
      },
      // 防伪管理
      {
        path: 'security',
        component: () => import('@/views/home/children/security/view.vue'),
        children: [
          { path: '', redirect: 'manage' },
          {
            path: 'manage',
            component: () => import('@/views/home/children/security/children/manage/view.vue'),
            children: [
              { path: '', redirect: 'list' },
              {
                path: 'list',
                component: () => import('@/views/home/children/security/children/manage/children/list/view.vue'),
              },
              {
                path: 'blank',
                component: () => import('@/views/home/children/security/children/manage/children/blank/view.vue'),
              },
            ],
          },
          {
            path: 'manage/applyblank',
            component: () => import('@/views/home/children/security/children/applyblank/view.vue'),
          },
          {
            path: 'manage/apply',
            component: () => import('@/views/home/children/security/children/apply/view.vue'),
          },
          {
            path: 'manage/import',
            component: () => import('@/views/home/children/security/children/import/view.vue'),
          },
          {
            path: 'manage/info',
            component: () => import('@/views/home/children/security/children/info/view.vue'),
          },
          { path: 'orderList', component: () => import('@/views/home/children/security/children/order/list.vue') },
          {
            path: 'orderList/order/detail/:id',
            component: () => import('@/views/home/children/security/children/order/detail.vue'),
          },
        ],
      },
      {
        path: 'security',
        component: BlankView,
        children: [],
      },
      // 仓库管理
      {
        path: 'warehouse',
        component: () => import('@/views/home/children/warehouse/view.vue'),
        children: [
          { path: '', redirect: 'list' },
          {
            path: 'list',
            component: () => import('@/views/home/children/warehouse/children/list/view.vue'),
            children: [
              {
                path: 'stock',
                component: () => import('@/views/home/children/warehouse/children/list/stock/view.vue'),
              },
              {
                path: 'summary',
                component: () => import('@/views/home/children/warehouse/children/list/summary/view.vue'),
              },
              {
                path: 'occupy',
                component: () => import('@/views/home/children/warehouse/children/list/occupy/view.vue'),
              },
            ],
          },
          {
            path: 'inwarehouse',
            component: () => import('@/views/home/children/warehouse/children/inwarehouse/view.vue'),
          },
          { path: 'oweGoods', component: () => import('@/views/home/children/warehouse/children/oweGoods/view.vue') },
          {
            path: 'oweGoods/export',
            component: () => import('@/views/home/children/warehouse/children/oweGoods/export.vue'),
          },
          {
            path: 'list/inOutDetails/:id',
            component: () => import('@/views/home/children/warehouse/children/inOutDetails/view.vue'),
          },
          {
            path: 'list/inWarehouseManual/in',
            component: () => import('@/views/home/children/warehouse/children/list/children/in/view.vue'),
          },
          {
            path: 'oweGoods/oweGoodsDetail/:userId',
            component: () => import('@/views/home/children/warehouse/children/oweGoods/detail.vue'),
          },
          {
            path: 'stock/early/warning',
            component: () => import('@/views/home/children/warehouse/children/stockEarlyWarning/view.vue'),
          },
          { path: 'manage', component: () => import('@/views/home/children/warehouse/children/manage/view.vue') },
          {
            path: 'manage/edit/:warehouseId',
            component: () => import('@/views/home/children/warehouse/children/manage/edit.vue'),
          },
        ],
      },
      // 充值导出excel
      {
        path: 'recharge',
        component: BlankView,
        children: [
          { path: 'export', component: () => import('@/views/home/children/recharge/children/export/view.vue') },
        ],
      },
      // 订单管理
      {
        path: 'order',
        component: () => import('@/views/home/children/order/view.vue'),
        children: [
          { path: 'deliver', component: () => import('@/views/home/children/order/children/list/deliver.vue') },
          { path: 'agent', component: () => import('@/views/home/children/order/children/list/agent.vue') },
          {
            path: 'artificialOrder',
            component: () => import('@/views/home/children/order/children/list/artificialOrder.vue'),
          },
          {
            path: 'artificialOrder/createArtificialOrder',
            component: () => import('@/views/home/children/order/children/list/createArtificialOrder.vue'),
          },
          { path: 'cloud', component: () => import('@/views/home/children/order/children/list/cloud.vue') },
          { path: 'take', component: () => import('@/views/home/children/order/children/list/take.vue') },
          {
            path: 'batch',
            name: 'batch',
            component: () => import('@/views/home/children/order/children/list/take.vue'),
          },
          { path: 'agentCloud', component: () => import('@/views/home/children/order/children/list/agentCloud.vue') },
          {
            path: 'deliver/logistics',
            component: () => import('@/views/home/children/order/children/list/logistics.vue'),
          },
          {
            path: 'deliver/detail/:id',
            component: () => import('@/views/home/children/order/children/list/detail.vue'),
          },
          {
            path: 'agent/detail/:id',
            component: () => import('@/views/home/children/order/children/list/detail.vue'),
          },
          {
            path: 'cloud/detail/:id',
            component: () => import('@/views/home/children/order/children/list/detail.vue'),
          },
          { path: 'take/detail/:id', component: () => import('@/views/home/children/order/children/list/detail.vue') },
          {
            path: 'batch/detail/:id',
            component: () => import('@/views/home/children/order/children/list/detail.vue'),
          },
          {
            path: 'agentCloud/detail/:id',
            component: () => import('@/views/home/children/order/children/list/detail.vue'),
          },
          { path: 'deliver/import', component: () => import('@/views/home/children/order/children/import/view.vue') },
          { path: 'deliver/export', component: () => import('@/views/home/children/order/children/export/view.vue') },
          { path: 'cloud/export', component: () => import('@/views/home/children/order/children/export/view.vue') },
          {
            path: 'agentCloud/export',
            component: () => import('@/views/home/children/order/children/export/view.vue'),
          },
          { path: 'take/export', component: () => import('@/views/home/children/order/children/export/view.vue') },
          { path: 'agent/export', component: () => import('@/views/home/children/order/children/export/view.vue') },
          { path: 'deliver/print', component: () => import('@/views/home/children/order/children/print/list.vue') },
          {
            path: 'deliver/print/preview',
            component: () => import('@/views/home/children/order/children/print/preview/view.vue'),
          },
          { path: 'take/print', component: () => import('@/views/home/children/order/children/print/list.vue') },
          {
            path: 'take/print/preview',
            component: () => import('@/views/home/children/order/children/print/preview/view.vue'),
          },
          {
            path: 'deliver/print/order',
            component: () => import('@/views/home/children/order/children/printOrder/view.vue'),
          },
          {
            path: 'express',
            component: () => import('@/views/home/children/express/view.vue'),
            children: [
              { path: 'base', component: () => import('@/views/home/children/express/children/base/view.vue') },
              {
                path: 'senderaddress',
                component: () => import('@/views/home/children/senderaddress/children/manger/view.vue'),
              },
              {
                path: 'setting',
                component: () => import('@/views/home/children/express/children/setting/setting.vue'),
              },
              {
                path: 'freight',
                component: () => import('@/views/home/children/express/children/freight/list/view.vue'),
              },
              { path: 'unit', component: () => import('@/views/home/children/express/children/unit/list/view.vue') },
              {
                path: 'logistics',
                component: () => import('@/views/home/children/system/children/logistics/view.vue'),
              },
              // 物流轨迹费用
              {
                path: 'logistic-path',
                component: () => import('@/views/home/children/express/children/logistics/view.vue'),
              },
            ],
          },
          {
            path: 'express',
            component: BlankView,
            children: [
              {
                path: 'freight',
                component: BlankView,
                children: [
                  {
                    path: 'create/goods',
                    component: () => import('@/views/home/children/express/children/freight/create/goods.vue'),
                  },
                  {
                    path: 'create/order',
                    component: () => import('@/views/home/children/express/children/freight/create/order.vue'),
                  },
                  {
                    path: 'create/group',
                    component: () => import('@/views/home/children/express/children/freight/create/group.vue'),
                  },
                  {
                    path: 'edit/goods/:id',
                    component: () => import('@/views/home/children/express/children/freight/edit/goods.vue'),
                  },
                  {
                    path: 'edit/order/:id',
                    component: () => import('@/views/home/children/express/children/freight/edit/order.vue'),
                  },
                  {
                    path: 'edit/group/:id',
                    component: () => import('@/views/home/children/express/children/freight/create/group.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: 'setting',
            component: () => import('@/views/home/children/order/children/orderSeting/view.vue'),
            children: [
              { path: '', redirect: 'first' },
              {
                path: 'first',
                component: () => import('@/views/home/children/order/children/orderSeting/children/first/view.vue'),
              },
              {
                path: 'submit',
                component: () => import('@/views/home/children/order/children/orderSeting/children/submit/view.vue'),
              },
              {
                path: 'transfer',
                component: () => import('@/views/home/children/order/children/orderSeting/children/transfer/view.vue'),
              },
              {
                path: 'take',
                component: () => import('@/views/home/children/order/children/orderSeting/children/take/view.vue'),
              },
              {
                path: 'exchange',
                component: () => import('@/views/home/children/order/children/orderSeting/children/exchange/view.vue'),
              },
              {
                path: 'exchange/add',
                name: 'Exchange_Add',
                component: () => import('@/views/home/children/order/children/orderSeting/children/exchange/add.vue'),
              },
              {
                path: 'exchange/edit/:id',
                name: 'Exchange_Edit',
                component: () => import('@/views/home/children/order/children/orderSeting/children/exchange/add.vue'),
              },
              {
                path: 'amount',
                component: () => import('@/views/home/children/order/children/orderSeting/children/amount/view.vue'),
              },
              {
                path: 'amount/edit/:id',
                component: () => import('@/views/home/children/order/children/orderSeting/children/amount/edit.vue'),
              },
              {
                path: 'takeAmountLimit',
                component: () =>
                  import('@/views/home/children/order/children/orderSeting/children/takeAmountLimit/view.vue'),
              },
              {
                path: 'takeAmountLimit/edit/:id',
                component: () =>
                  import('@/views/home/children/order/children/orderSeting/children/takeAmountLimit/edit.vue'),
              },
              {
                path: 'wait-pay',
                component: () => import('@/views/home/children/order/children/orderSeting/children/waitPay/view.vue'),
              },
            ],
          },
          // 售后设置
          {
            path: 'after/sale',
            component: () => import('@/views/home/children/order/children/afterSale/view.vue'),
            children: [
              { path: '', redirect: 'refund' },
              {
                path: 'refund',
                component: () => import('@/views/home/children/order/children/afterSale/children/refund/view.vue'),
              },
              {
                path: 'exchange',
                component: () => import('@/views/home/children/order/children/afterSale/children/exchange/view.vue'),
              },
            ],
          },
          { path: 'proactive', component: () => import('@/views/home/children/order/children/list/proactive.vue') },
          {
            path: 'proactive/detail/:id',
            component: () => import('@/views/home/children/order/children/list/proactiveDetail.vue'),
          },
          {
            path: 'proactive/export',
            component: () => import('@/views/home/children/order/children/export/view.vue'),
          },
          // 散客订单
          {
            path: 'guest',
            component: BlankView,
            children: [
              { path: '', redirect: 'list' },
              {
                path: 'list',
                component: () => import('@/views/home/children/order/children/guest/list/index.vue'),
              },
              {
                path: 'create',
                component: () => import('@/views/home/children/order/children/guest/create/index.vue'),
              },
              {
                path: 'export',
                component: () => import('@/views/home/children/order/children/guest/export/index.vue'),
              },

              {
                path: 'detail/:id',
                component: () => import('@/views/home/children/order/children/list/detail.vue'),
              },
            ],
          },
        ],
      },
      // 代理管理
      {
        path: 'agent',
        component: () => import('@/views/home/children/agent/view.vue'),
        children: [
          { path: '', redirect: 'list' },
          { path: 'list', component: () => import('@/views/home/children/agent/children/agentInfo/list/view.vue') },
          {
            path: 'list/export',
            component: () => import('@/views/home/children/agent/children/agentInfo/list/export.vue'),
          },
          {
            path: 'list/cloud/export',
            component: () => import('@/views/home/children/agent/children/agentInfo/list/cloudExport.vue'),
          },
          {
            path: 'distribution/record',
            component: () => import('@/views/home/children/agent/children/distribution/record/list.vue'),
          },
          {
            path: 'selfDelivery',
            component: () => import('@/views/home/children/agent/children/selfDelivery/list.vue'),
          },
          { path: 'delete', component: () => import('@/views/home/children/agent/children/deleteAgent/delete.vue') },
          {
            path: 'delete/export',
            component: () => import('@/views/home/children/agent/children/deleteAgent/export.vue'),
          },
          { path: 'back/list', component: () => import('@/views/home/children/agent/children/agentBack/list.vue') },
          { path: 'agentArea', component: () => import('@/views/home/children/agent/children/agentArea/view.vue') },
          { path: 'regress', component: () => import('@/views/home/children/agent/children/regress/list.vue') },
          {
            path: 'resourceArea',
            component: () => import('@/views/home/children/agent/children/resourceArea/list.vue'),
          },
          { path: 'renewal', component: () => import('@/views/home/children/agent/children/renewal/list.vue') },
          {
            path: 'list/agentEdit/:userId',
            component: () => import('@/views/home/children/agent/children/edit/children/view.vue'),
          },
          { path: 'invalid/list', component: () => import('@/views/home/children/agent/children/invalid/list.vue') },
          { path: 'newAgentRecord', component: () => import('@/views/home/children/agent/children/new/list.vue') },
          { path: 'newAgentRecord/new', component: () => import('@/views/home/children/agent/children/new/new.vue') },
          {
            path: 'list/agentCheck/:id',
            component: () => import('@/views/home/children/agent/children/agentInfo/view.vue'),
            children: [
              { path: '', redirect: 'agentInfo' },
              {
                path: 'agentInfo',
                component: () => import('@/views/home/children/agent/children/agentInfo/check/children/view.vue'),
              },
              {
                path: 'upgrade',
                component: () => import('@/views/home/children/agent/children/agentInfo/check/children/upGrade.vue'),
              },
              {
                path: 'recharge',
                component: () => import('@/views/home/children/agent/children/agentInfo/check/children/recharge.vue'),
              },
              {
                path: 'profit',
                component: () => import('@/views/home/children/agent/children/agentInfo/profit/view.vue'),
              },
              {
                path: 'orderRecord',
                component: () => import('@/views/home/children/agent/children/agentInfo/orderRecord/view.vue'),
              },
              {
                path: 'deliverRecord',
                component: () => import('@/views/home/children/agent/children/agentInfo/deliverRecord/view.vue'),
              },
              {
                path: 'subordinateAgent/:teamId',
                component: () => import('@/views/home/children/agent/children/agentInfo/subordinateAgent/view.vue'),
              },
              {
                path: 'warehouse/local',
                component: () => import('@/views/home/children/agent/children/agentInfo/warehouse/local/view.vue'),
              },
              {
                path: 'warehouse/cloud',
                component: () => import('@/views/home/children/agent/children/agentInfo/warehouse/cloud/view.vue'),
              },
              {
                path: 'recommend',
                component: () => import('@/views/home/children/agent/children/agentInfo/recommend/view.vue'),
              },
              {
                path: 'invite',
                component: () => import('@/views/home/children/agent/children/agentInfo/invite/view.vue'),
              },
              {
                path: 'superior/record',
                component: () => import('@/views/home/children/agent/children/agentInfo/superiorRecord/view.vue'),
              },
              {
                path: 'details',
                component: () => import('@/views/home/children/agent/children/agentInfo/rechargeDetail/view.vue'),
              },
              {
                path: 'wallet',
                component: () => import('@/views/home/children/agent/children/agentInfo/wallet/wallet.vue'),
                children: [
                  {
                    path: 'payment',
                    component: () =>
                      import('@/views/home/children/agent/children/agentInfo/wallet/children/payment.vue'),
                  },
                  {
                    path: 'balance',
                    component: () =>
                      import('@/views/home/children/agent/children/agentInfo/wallet/children/balance.vue'),
                  },
                  {
                    path: 'bonus',
                    component: () => import('@/views/home/children/agent/children/agentInfo/bonus/view.vue'),
                  },
                  {
                    path: 'insure',
                    component: () => import('@/views/home/children/agent/children/agentInfo/insure/insure.vue'),
                  },
                ],
              },
              // 变更记录
              {
                path: 'changeRecord',
                component: () => import('@/views/home/children/agent/children/agentInfo/changeRecord/view.vue'),
                children: [
                  { path: '', redirect: 'upgrade' },
                  {
                    path: 'upgrade',
                    component: () =>
                      import('@/views/home/children/agent/children/agentInfo/check/children/upGrade.vue'),
                  },
                  {
                    path: 'superiorRecord',
                    component: () => import('@/views/home/children/agent/children/agentInfo/superiorRecord/view.vue'),
                  },
                  {
                    path: 'inviterRecord',
                    component: () =>
                      import(
                        '@/views/home/children/agent/children/agentInfo/changeRecord/children/inviterChangeRecord.vue'
                      ),
                  },
                  {
                    path: 'areaRecord',
                    component: () =>
                      import(
                        '@/views/home/children/agent/children/agentInfo/changeRecord/children/areaChangeRecord.vue'
                      ),
                  },
                ],
              },

              {
                path: 'changeLevel',
                component: () => import('@/views/home/children/agent/children/agentInfo/changeLevel/view.vue'),
              },
              {
                path: 'address',
                component: () => import('@/views/home/children/agent/children/agentInfo/address/view.vue'),
              },
              {
                path: 'points',
                component: () => import('@/views/home/children/agent/children/agentInfo/points/view.vue'),
              },
              {
                path: 'coupon',
                component: () => import('@/views/home/children/agent/children/agentInfo/coupon/view.vue'),
              },
              {
                path: 'shopInfo',
                component: () => import('@/views/home/children/agent/children/agentInfo/shopInfo/view.vue'),
                children: [
                  { path: '', redirect: 'wallet' },
                  { path: 'wallet', component: () => import('@/views/home/children/memberInfo/wallet/view.vue') },
                  { path: 'reward', component: () => import('@/views/home/children/memberInfo/bonusDetail/view.vue') },
                  {
                    path: 'levelChange',
                    component: () => import('@/views/home/children/memberInfo/levelChange/view.vue'),
                  },
                  {
                    path: 'orderRecord',
                    component: () => import('@/views/home/children/memberInfo/orderRecord/view.vue'),
                  },
                  { path: 'bindVip', component: () => import('@/views/home/children/memberInfo/bindVip/view.vue') },
                ],
              },
              {
                path: 'shopsInfo',
                component: () => import('../views/home/children/agent/children/agentInfo/newShop/view.vue'),
              },
            ],
          },
          {
            path: 'delete/agentCheck/:id',
            component: () => import('@/views/home/children/agent/children/agentInfo/view.vue'),
            children: [
              { path: '', redirect: 'agentInfo' },
              {
                path: 'agentInfo',
                component: () => import('@/views/home/children/agent/children/agentInfo/check/children/view.vue'),
              },
              {
                path: 'upgrade',
                component: () => import('@/views/home/children/agent/children/agentInfo/check/children/upGrade.vue'),
              },
              {
                path: 'recharge',
                component: () => import('@/views/home/children/agent/children/agentInfo/check/children/recharge.vue'),
              },
              {
                path: 'profit',
                component: () => import('@/views/home/children/agent/children/agentInfo/profit/view.vue'),
              },
              {
                path: 'orderRecord',
                component: () => import('@/views/home/children/agent/children/agentInfo/orderRecord/view.vue'),
              },
              {
                path: 'deliverRecord',
                component: () => import('@/views/home/children/agent/children/agentInfo/deliverRecord/view.vue'),
              },
              {
                path: 'subordinateAgent/:teamId',
                component: () => import('@/views/home/children/agent/children/agentInfo/subordinateAgent/view.vue'),
              },
              {
                path: 'warehouse/local',
                component: () => import('@/views/home/children/agent/children/agentInfo/warehouse/local/view.vue'),
              },
              {
                path: 'warehouse/cloud',
                component: () => import('@/views/home/children/agent/children/agentInfo/warehouse/cloud/view.vue'),
              },
              {
                path: 'recommend',
                component: () => import('@/views/home/children/agent/children/agentInfo/recommend/view.vue'),
              },
              {
                path: 'insure',
                component: () => import('@/views/home/children/agent/children/agentInfo/insure/insure.vue'),
              },
              {
                path: 'superior/record',
                component: () => import('@/views/home/children/agent/children/agentInfo/superiorRecord/view.vue'),
              },
              {
                path: 'details',
                component: () => import('@/views/home/children/agent/children/agentInfo/rechargeDetail/view.vue'),
              },
              {
                path: 'bonus',
                component: () => import('@/views/home/children/agent/children/agentInfo/bonus/view.vue'),
              },
              // 变更记录
              {
                path: 'changeRecord',
                component: () => import('@/views/home/children/agent/children/agentInfo/changeRecord/view.vue'),
                children: [
                  { path: '', redirect: 'upgrade' },
                  {
                    path: 'upgrade',
                    component: () =>
                      import('@/views/home/children/agent/children/agentInfo/check/children/upGrade.vue'),
                  },
                  {
                    path: 'superiorRecord',
                    component: () => import('@/views/home/children/agent/children/agentInfo/superiorRecord/view.vue'),
                  },
                  {
                    path: 'inviterRecord',
                    component: () =>
                      import(
                        '@/views/home/children/agent/children/agentInfo/changeRecord/children/inviterChangeRecord.vue'
                      ),
                  },
                ],
              },

              {
                path: 'changeLevel',
                component: () => import('@/views/home/children/agent/children/agentInfo/changeLevel/view.vue'),
              },
              {
                path: 'address',
                component: () => import('@/views/home/children/agent/children/agentInfo/address/view.vue'),
              },
            ],
          },
          {
            path: 'list/distribution/:userId',
            component: () => import('@/views/home/children/agent/children/distribution/view.vue'),
          },
          {
            path: 'list/delete/:userId',
            component: () => import('@/views/home/children/agent/children/delete/view.vue'),
          },
          {
            path: 'distribution/record/detail/:id',
            component: () => import('@/views/home/children/agent/children/distribution/record/detail.vue'),
          },
          {
            path: 'shop',
            component: () => import('@/views/home/children/agent/children/newShop/view.vue'),
            children: [
              { path: '', redirect: 'list' },
              { path: 'list', component: () => import('@/views/home/children/agent/children/newShop/list.vue') },
              {
                path: 'list/edit/:id',
                component: () => import('@/views/home/children/agent/children/newShop/edit.vue'),
              },
              { path: 'setting', component: () => import('@/views/home/children/agent/children/newShop/setting.vue') },
              { path: 'map', component: () => import('@/views/home/children/agent/children/newShop/map.vue') },
            ],
          },
          {
            path: 'regress/detail/:id',
            component: () => import('@/views/home/children/agent/children/regress/detail.vue'),
          },
          { path: 'tag', component: () => import('@/views/home/children/agent/children/tag/list.vue') },
          { path: 'tag/add', component: () => import('@/views/home/children/agent/children/tag/add.vue') },
          { path: 'chain', component: () => import('@/views/home/children/agent/children/chain/list.vue') },
          { path: 'purchase', component: () => import('@/views/home/children/agent/children/purchase/list.vue') },
        ],
      },
      {
        path: 'agent',
        component: BlankView,
        children: [
          {
            path: 'back/detail/:id',
            component: () => import('@/views/home/children/agent/children/agentBack/detail.vue'),
          },
          // 变更邀请人
          {
            path: 'change/inviterRecord/:id',
            component: () => import('@/views/home/children/agent/children/agentInfo/changeRecord/children/change.vue'),
          },
        ],
      },

      // 奖励管理
      {
        path: 'reward',
        component: () => import('@/views/home/children/reward/view.vue'),
        children: [
          { path: 'rebate', component: () => import('@/views/home/children/reward/children/rebate/list.vue') },
          {
            path: 'setting',
            component: () => import('@/views/home/children/reward/children/setting/view.vue'),
            children: [
              {
                path: 'recharge',
                component: () => import('@/views/home/children/reward/children/setting/recharge.vue'),
              },
              { path: 'basic', component: () => import('@/views/home/children/reward/children/setting/basic.vue') },
              {
                path: 'basic/history',
                component: () => import('@/views/home/children/reward/children/setting/history/basicHistory.vue'),
              },
              {
                path: 'check',
                component: () => import('@/views/home/children/reward/children/setting/salesCheckPrice/view.vue'),
              },
              {
                path: 'check/history',
                component: () => import('@/views/home/children/reward/children/setting/history/checkPriceHistory.vue'),
              },
              {
                path: 'sales',
                component: () => import('@/views/home/children/reward/children/setting/sales/view.vue'),
              },
              {
                path: 'sales/singleHistory',
                component: () => import('@/views/home/children/reward/children/setting/history/singleSetHistory.vue'),
              },
              {
                path: 'sales/comprehensiveHistory',
                component: () =>
                  import('@/views/home/children/reward/children/setting/history/comprehensiveHistory.vue'),
              },
              {
                path: 'invitationFirst',
                component: () => import('@/views/home/children/reward/children/setting/invitationFirst/view.vue'),
              },
              {
                path: 'invitationFirst/history',
                component: () => import('@/views/home/children/reward/children/setting/history/inviteFirstHistory.vue'),
              },
              {
                path: 'invitationLater',
                component: () => import('@/views/home/children/reward/children/setting/invitationLater/view.vue'),
              },
              {
                path: 'invitationLater/history',
                component: () => import('@/views/home/children/reward/children/setting/history/inviteLaterHistory.vue'),
              },
              {
                path: 'manage',
                component: () => import('@/views/home/children/reward/children/setting/manage/view.vue'),
              },
              {
                path: 'manage/history',
                component: () => import('@/views/home/children/reward/children/setting/history/manageHistory.vue'),
              },
              {
                path: 'upgrade',
                component: () => import('@/views/home/children/reward/children/setting/upgrade/view.vue'),
              },
              {
                path: 'upgrade/history',
                component: () => import('@/views/home/children/reward/children/setting/history/upgradeHistory.vue'),
              },
              { path: 'area', component: () => import('@/views/home/children/reward/children/setting/area/view.vue') },
              {
                path: 'area/history',
                component: () => import('@/views/home/children/reward/children/setting/history/areaHistory.vue'),
              },
              {
                path: 'allowance',
                component: () => import('@/views/home/children/reward/children/setting/allowance/view.vue'),
              },
              {
                path: 'allowance/history',
                component: () => import('@/views/home/children/reward/children/setting/history/allowanceHistory.vue'),
              },
              {
                path: 'share',
                component: () => import('@/views/home/children/reward/children/setting/share/view.vue'),
              },
              {
                path: 'share/history',
                component: () => import('@/views/home/children/reward/children/setting/history/shareHistory.vue'),
              },
            ],
          },
          { path: 'real', component: () => import('@/views/home/children/reward/children/real/list.vue') },
          { path: 'audit/list', component: () => import('@/views/home/children/reward/children/audit/list.vue') },
          { path: 'bonus', component: () => import('@/views/home/children/reward/children/bonus/list.vue') },
          {
            path: 'area',
            component: () => import('@/views/home/children/reward/children/area/view.vue'),
            children: [
              {
                path: 'list',
                component: () => import('@/views/home/children/reward/children/area/children/list.vue'),
              },
              {
                path: 'record',
                component: () => import('@/views/home/children/reward/children/area/children/record.vue'),
              },
              {
                path: 'compliance',
                component: () => import('@/views/home/children/reward/children/area/children/compliance.vue'),
              },
            ],
          },
          {
            path: 'area/detail/:id',
            component: () => import('@/views/home/children/reward/children/area/children/detail.vue'),
          },
          {
            path: 'allowance/agentList',
            component: () => import('@/views/home/children/reward/children/allowance/children/detail.vue'),
          },
          { path: 'allowance', component: () => import('@/views/home/children/reward/children/allowance/view.vue') },
          {
            path: 'real/detail/:userId',
            component: () => import('@/views/home/children/reward/children/real/detail.vue'),
          },
          {
            path: 'rebate/detail/:id',
            component: () => import('@/views/home/children/reward/children/rebate/detail.vue'),
          },
          {
            path: 'audit/list/rebate/:userId',
            component: () => import('@/views/home/children/reward/children/audit/rebateDetail.vue'),
          },
          {
            path: 'audit/list/sales/:userId',
            component: () => import('@/views/home/children/reward/children/audit/salesDetail.vue'),
          },
          { path: 'bonus/detail', component: () => import('@/views/home/children/reward/children/bonus/detail.vue') },
          { path: 'pending', component: () => import('@/views/home/children/reward/children/pending/list.vue') },
        ],
      },

      // APP管理
      {
        path: 'app',
        component: () => import('@/views/home/children/app/view.vue'),
        children: [
          {
            path: 'homePage',
            component: () => import('@/views/home/children/app/children/homePage/view.vue'),
            children: [
              { path: 'index', component: () => import('@/views/home/children/app/children/homePage/index/view.vue') },
              {
                path: 'startup',
                component: () => import('@/views/home/children/app/children/homePage/startup/view.vue'),
              },
              { path: 'guide', component: () => import('@/views/home/children/app/children/homePage/guide/view.vue') },
            ],
          },
          { path: 'agent/invite', component: () => import('@/views/home/children/app/children/agentInvite/view.vue') },
        ],
      },
      // 发货地址管理
      {
        path: 'senderaddress/manger',
        component: () => import('@/views/home/children/senderaddress/children/manger/view.vue'),
      },
      {
        path: '',
        component: BlankView,
        children: [
          {
            path: 'rechargeDetail/:userId',
            component: () => import('@/views/home/children/recharge/children/agent/recharge.vue'),
          },
        ],
      },
      {
        path: '',
        component: BlankView,
        children: [
          {
            path: 'deductDetail/:userId',
            component: () => import('@/views/home/children/recharge/children/agent/deduct.vue'),
          },
        ],
      },
      // 培训管理
      {
        path: 'train',
        component: () => import('@/views/home/children/train/view.vue'),
        children: [
          { path: '', redirect: 'start/push' },
          { path: 'start/push', component: () => import('@/views/home/children/train/children/startPush/view.vue') },
        ],
      },
      // 消息中心
      {
        path: 'message',
        component: () => import('@/views/home/children/message/view.vue'),
        children: [
          { path: '', redirect: 'announcement' },
          {
            path: 'announcement',
            component: () => import('@/views/home/children/message/children/announcement/list.vue'),
          },
          {
            path: 'announcement/create',
            component: () => import('@/views/home/children/message/children/announcement/create.vue'),
          },
          {
            path: 'announcement/check',
            component: () => import('@/views/home/children/message/children/announcement/check.vue'),
          },
          {
            path: 'announcement/edit',
            component: () => import('@/views/home/children/message/children/announcement/edit.vue'),
          },
        ],
      },
      // 素材中心
      {
        path: 'material',
        component: () => import('@/views/home/children/material/view.vue'),
        children: [
          { path: 'center', component: () => import('@/views/home/children/material/children/center/view.vue') },
          {
            path: 'center/edit/:materialId',
            component: () => import('@/views/home/children/material/children/edit/view.vue'),
          },
          { path: 'type', component: () => import('@/views/home/children/material/children/type/view.vue') },
          { path: 'library', component: () => import('@/views/home/children/material/children/image/view.vue') },
        ],
      },
      {
        path: 'material',
        component: BlankView,
        children: [
          {
            path: 'edit/:materialId',
            component: () => import('@/views/home/children/material/children/edit/view.vue'),
          },
        ],
      },
      // 资产管理
      {
        path: 'assets',
        component: () => import('@/views/home/children/assets/view.vue'),
        children: [
          { path: 'list', component: () => import('@/views/home/children/assets/children/centralBank/list.vue') },
          { path: 'profit', component: () => import('@/views/home/children/assets/children/profit/view.vue') },
          {
            path: 'profit/export',
            component: () => import('@/views/home/children/assets/children/profit/exportExcel.vue'),
          },
          //  可提现余额管理
          {
            path: 'withdrawal',
            component: () => import('@/views/home/children/recharge/children/withdrawal/list.vue'),
          },
          {
            path: 'withdrawal/export',
            component: () => import('@/views/home/children/recharge/children/withdrawal/export.vue'),
          },
          // 充值管理
          {
            path: 'recharge',
            component: () => import('@/views/home/children/recharge/view.vue'),
            children: [
              { path: '', redirect: 'record' },
              { path: 'record', component: () => import('@/views/home/children/recharge/children/record/view.vue') },
              { path: 'total', component: () => import('@/views/home/children/recharge/children/total/list.vue') },
            ],
          },
          {
            path: 'rechargeDetail/:userId',
            component: () => import('@/views/home/children/recharge/children/agent/recharge.vue'),
          },
          {
            path: 'recharge/export',
            component: () => import('@/views/home/children/recharge/children/export/view.vue'),
          },
          // 设置
          {
            path: 'setting',
            component: () => import('@/views/home/children/assets/children/setting/view.vue'),
            children: [
              { path: '', redirect: 'takecashSetting' },
              {
                path: 'recharge',
                component: () => import('@/views/home/children/assets/children/setting/children/recharge.vue'),
              },
              {
                path: 'takecashSetting',
                component: () => import('@/views/home/children/assets/children/setting/children/takeCashSetting.vue'),
              },
              {
                path: 'receiptsAccountSetting',
                component: () =>
                  import('@/views/home/children/assets/children/setting/children/receiptAccountSetting.vue'),
              },
            ],
          },
          //  冻结记录
          { path: 'freeze', component: () => import('@/views/home/children/assets/children/freeze/view.vue') },
          // 在线充值记录
          { path: 'online', component: () => import('@/views/home/children/assets/children/rechargeRecord/view.vue') },
          // 导出在线充值表
          {
            path: 'online/export',
            component: () => import('@/views/home/children/assets/children/rechargeRecord/exportExcel.vue'),
          },
          // 汇聚交易明细
          {
            path: 'transaction',
            component: () => import('@/views/home/children/assets/children/transaction/view.vue'),
            children: [
              // {path: '', redirect: 'takecashSetting'},
              {
                path: 'enter',
                component: () => import('@/views/home/children/assets/children/transaction/enter.vue'),
              },
              {
                path: 'uploadIdCard',
                name: 'uploadIdCard',
                component: () => import('@/views/home/children/assets/children/transaction/enter.vue'),
              },
              {
                path: 'payRecord',
                component: () => import('@/views/home/children/assets/children/transaction/payRecord.vue'),
              },
              {
                path: 'refund',
                component: () => import('@/views/home/children/assets/children/transaction/refund.vue'),
              },
              {
                path: 'transfer',
                component: () => import('@/views/home/children/assets/children/transaction/transfer.vue'),
              },
              {
                path: 'takeCash',
                component: () => import('@/views/home/children/assets/children/transaction/takeCash.vue'),
              },
              {
                path: 'waitPay',
                component: () => import('@/views/home/children/assets/children/transaction/waitPayRecord.vue'),
              },
            ],
          },
        ],
      },
      // 利润明细导出excel
      {
        path: 'profit',
        component: BlankView,
        children: [
          { path: 'export', component: () => import('@/views/home/children/assets/children/profit/exportExcel.vue') },
        ],
      },
      // 分销商城
      {
        path: 'shop',
        component: () => import('@/views/home/children/shop/view.vue'),
        children: [
          {
            path: 'goods',
            component: () => import('@/views/home/children/shop/children/goods/view.vue'),
            children: [
              { path: 'list', component: () => import('@/views/home/children/shop/children/goods/productSort.vue') },
              {
                path: 'stock/early/warning',
                component: () => import('@/views/home/children/shop/children/goods/StockEarlyWarning/view.vue'),
              },
            ],
          },
          {
            path: 'goods/:id',
            component: () => import('@/views/home/children/shop/children/goods/children/edit.vue'),
          },
          {
            path: 'activity',
            component: () => import('@/views/home/children/shop/children/activity/view.vue'),
            children: [
              { path: '/', redirect: 'ordinary' },
              {
                path: 'ordinary',
                component: () => import('@/views/home/children/shop/children/activity/children/ordinary/activity.vue'),
              },
              {
                path: 'ordinary/create',
                component: () => import('@/views/home/children/shop/children/activity/children/ordinary/create.vue'),
              },
              {
                path: 'ordinary/edit/:id',
                component: () => import('@/views/home/children/shop/children/activity/children/ordinary/edit.vue'),
              },
              {
                path: 'oldChangeNew',
                component: () => import('@/views/home/children/shop/children/activity/children/oldChangeNew/view.vue'),
                children: [
                  { path: '/', redirect: 'list' },
                  {
                    path: 'list',
                    component: () =>
                      import('@/views/home/children/shop/children/activity/children/oldChangeNew/children/list.vue'),
                  },
                  {
                    path: 'setting',
                    component: () =>
                      import('@/views/home/children/shop/children/activity/children/oldChangeNew/children/setting.vue'),
                  },
                ],
              },
              {
                path: 'oldChangeNew/create',
                component: () =>
                  import('@/views/home/children/shop/children/activity/children/oldChangeNew/create.vue'),
              },
              {
                path: 'oldChangeNew/edit/:id',
                component: () => import('@/views/home/children/shop/children/activity/children/oldChangeNew/edit.vue'),
              },
              {
                path: 'luckyDraw',
                component: () => import('@/views/home/children/shop/children/activity/children/luckyDraw/view.vue'),
                children: [
                  { path: '/', redirect: 'actionList' },
                  {
                    path: 'actionList',
                    component: () =>
                      import('@/views/home/children/shop/children/activity/children/luckyDraw/children/actionList.vue'),
                  },
                  {
                    path: 'historyList',
                    component: () =>
                      import(
                        '@/views/home/children/shop/children/activity/children/luckyDraw/children/historyList.vue'
                      ),
                  },
                ],
              },
              {
                path: 'luckyDraw/create',
                component: () => import('@/views/home/children/shop/children/activity/children/luckyDraw/create.vue'),
              },
              {
                path: 'luckyDraw/detail/:id',
                component: () => import('@/views/home/children/shop/children/activity/children/luckyDraw/detail.vue'),
              },
              {
                path: 'luckyDraw/edit/:id',
                component: () => import('@/views/home/children/shop/children/activity/children/luckyDraw/edit.vue'),
              },
              {
                path: 'luckyDraw/exportHistory',
                component: () =>
                  import('@/views/home/children/shop/children/activity/children/luckyDraw/children/exportHistory.vue'),
              },
              // 代金券
              {
                path: 'voucher',
                component: () => import('@/views/home/children/shop/children/activity/children/voucher/view.vue'),
                children: [
                  { path: '/', redirect: 'activityList' },
                  {
                    path: 'activityList',
                    component: () =>
                      import('@/views/home/children/shop/children/activity/children/voucher/activityList.vue'),
                  },
                  {
                    path: 'relatedProducts',
                    component: () =>
                      import('@/views/home/children/shop/children/activity/children/voucher/relatedProducts.vue'),
                  },
                  {
                    path: 'send/bill',
                    component: () =>
                      import('@/views/home/children/shop/children/activity/children/voucher/voucherSendList.vue'),
                  },
                ],
              },
              {
                path: 'voucher/create',
                component: () => import('@/views/home/children/shop/children/activity/children/voucher/create.vue'),
              },
              {
                path: 'voucher/edit/:id',
                component: () => import('@/views/home/children/shop/children/activity/children/voucher/create.vue'),
              },
              {
                path: 'voucher/gift/:id',
                component: () =>
                  import('@/views/home/children/shop/children/activity/children/voucher/giftVoucher.vue'),
              },
              {
                path: 'voucher/list/agent/:voucherId',
                component: () =>
                  import('@/views/home/children/shop/children/activity/children/voucher/voucherAgentList.vue'),
              },
              {
                path: 'voucher/send/export',
                component: () =>
                  import('@/views/home/children/shop/children/activity/children/voucher/exportVoucher.vue'),
              },
              {
                path: 'upgrade/agent',
                component: () => import('@/views/home/children/shop/children/activity/children/upgradeAgent/view.vue'),
              },
              {
                path: 'upgrade/agent/edit/:id',
                component: () =>
                  import('@/views/home/children/shop/children/activity/children/upgradeAgent/newEdit.vue'),
              },
              {
                path: 'coupon',
                component: () => import('@/views/home/children/shop/children/activity/children/coupon/view.vue'),
              },
              {
                path: 'coupon/edit/:id',
                component: () => import('@/views/home/children/shop/children/activity/children/coupon/edit.vue'),
              },
              {
                path: 'coupon/present/:id',
                component: () => import('@/views/home/children/shop/children/activity/children/coupon/present.vue'),
              },
              {
                path: 'coupon/useCase/:id',
                component: () => import('@/views/home/children/shop/children/activity/children/coupon/useCase.vue'),
              },
              {
                path: 'firstOrder',
                component: () => import('@/views/home/children/shop/children/activity/children/firstOrder/view.vue'),
              },
              {
                path: 'firstOrder/edit/:id',
                component: () => import('@/views/home/children/shop/children/activity/children/firstOrder/edit.vue'),
              },
              {
                path: 'gift',
                component: () => import('@/views/home/children/shop/children/activity/children/gifts/activity.vue'),
              },
              {
                path: 'gift/create',
                component: () => import('@/views/home/children/shop/children/activity/children/gifts/create.vue'),
              },
              {
                path: 'gift/edit/:id',
                component: () => import('@/views/home/children/shop/children/activity/children/gifts/create.vue'),
              },
            ],
          },
          { path: 'homePage', component: () => import('@/views/home/children/shop/children/homePage/homePage.vue') },
          {
            path: 'homePage/newHomePage',
            component: () => import('@/views/home/children/shop/children/homePage/newHomePage.vue'),
          },
          { path: 'market', component: () => import('@/views/home/children/shop/children/market/market.vue') },
          { path: 'order', component: () => import('@/views/home/children/shop/children/order/list.vue') },
          {
            path: 'order/detail/:id',
            component: () => import('@/views/home/children/shop/children/order/detail.vue'),
          },
          /* 打印预览电子面单 */
          {
            path: 'order/print/list',
            component: () => import('@/views/home/children/shop/children/order/print/list.vue'),
          },
          {
            path: 'order/print/preview',
            component: () => import('@/views/home/children/shop/children/order/print/preview.vue'),
          },
          // 打印配货单
          {
            path: 'order/print/order',
            component: () => import('@/views/home/children/shop/children/order/printOrder/view.vue'),
          },
          {
            path: 'order/import',
            component: () => import('@/views/home/children/shop/children/order/import/view.vue'),
          },
          {
            path: 'order/export',
            component: () => import('@/views/home/children/shop/children/order/export/view.vue'),
          },
          /*客户*/
          {
            path: 'customer',
            component: () => import('@/views/home/children/shop/children/customer/view.vue'),
            children: [
              { path: 'list', component: () => import('@/views/home/children/shop/children/customer/list.vue') },
              {
                path: 'walletRecord',
                component: () => import('@/views/home/children/shop/children/customer/walletChangeRecord/list.vue'),
              },
            ],
          },
          {
            path: 'customer/export',
            component: () => import('@/views/home/children/shop/children/customer/export/view.vue'),
          },
          {
            path: 'customer/detail/:id',
            component: () => import('@/views/home/children/shop/children/customer/detail/view.vue'),
            children: [
              { path: '', redirect: 'wallet' },
              { path: 'wallet', component: () => import('@/views/home/children/memberInfo/wallet/view.vue') },
              { path: 'voucher', component: () => import('@/views/home/children/memberInfo/voucher/view.vue') },
              { path: 'bonus', component: () => import('@/views/home/children/memberInfo/bonusDetail/view.vue') },
              { path: 'level', component: () => import('@/views/home/children/memberInfo/levelChange/view.vue') },
              {
                path: 'invite',
                component: () => import('@/views/home/children/shop/children/customer/detail/invite/view.vue'),
              },
              {
                path: 'winning',
                component: () => import('@/views/home/children/shop/children/customer/detail/winning/view.vue'),
              },
              {
                path: 'share/good',
                component: () => import('@/views/home/children/shop/children/customer/detail/shareGood/view.vue'),
              },
              {
                path: 'coupon',
                component: () => import('@/views/home/children/shop/children/customer/detail/coupon/view.vue'),
              },
              {
                path: 'reset/record',
                component: () => import('@/views/home/children/shop/children/customer/detail/resetRecord/view.vue'),
              },
              {
                path: 'orderRecord',
                component: () => import('@/views/home/children/memberInfo/orderRecord/view.vue'),
              },
            ],
          },
          {
            path: 'customer/walletRecord/export',
            component: () => import('@/views/home/children/shop/children/customer/walletChangeRecord/export.vue'),
          },
          /*评论*/
          { path: 'comment', component: () => import('@/views/home/children/shop/children/comment/view.vue') },
          {
            path: 'comment/detail/:id',
            component: () => import('@/views/home/children/shop/children/comment/detail.vue'),
          },
          {
            path: 'seting',
            component: () => import('@/views/home/children/shop/children/seting/seting.vue'),
            children: [
              { path: '', redirect: 'member' },
              {
                path: 'kefuSeting',
                component: () => import('@/views/home/children/shop/children/seting/children/kefuSeting.vue'),
              },
              {
                path: 'member',
                component: () => import('@/views/home/children/shop/children/seting/children/memberSetting/index.vue'),
              },
              {
                path: 'brandSeting',
                component: () => import('@/views/home/children/shop/children/seting/children/brandSeting.vue'),
              },
              {
                path: 'common',
                component: () => import('@/views/home/children/shop/children/seting/children/commonSetting.vue'),
              },
              {
                path: 'afterSaleSeting',
                component: () => import('@/views/home/children/shop/children/seting/children/afterSaleSeting.vue'),
                children: [
                  {
                    path: 'carriageSeting',
                    component: () => import('@/views/home/children/shop/children/seting/children/carriageSeting.vue'),
                  },
                  {
                    path: 'refundSetting',
                    component: () => import('@/views/home/children/shop/children/seting/children/refundSetting.vue'),
                  },
                  {
                    path: 'exchangeList',
                    component: () => import('@/views/home/children/shop/children/seting/children/exchange/view.vue'),
                  },
                ],
              },
              {
                path: 'paySeting',
                component: () => import('@/views/home/children/shop/children/seting/children/paySeting.vue'),
              },
              {
                path: 'shopCode',
                component: () => import('@/views/home/children/shop/children/seting/children/shopCode/view.vue'),
              },
              {
                path: 'copyrightText',
                component: () => import('@/views/home/children/shop/children/seting/children/copyrightText.vue'),
              },
              {
                path: 'rebateSetting',
                component: () => import('@/views/home/children/shop/children/seting/children/rebateSetting.vue'),
              },
              {
                path: 'freight',
                component: () => import('@/views/home/children/shop/children/express/list/view.vue'),
              },
              {
                path: 'amount',
                component: () => import('@/views/home/children/shop/children/seting/children/amount/view.vue'),
              },
              {
                path: 'amount/edit/:id',
                component: () => import('@/views/home/children/shop/children/seting/children/amount/edit.vue'),
              },
              {
                path: 'securityPassword',
                component: () => import('@/views/home/children/shop/children/seting/children/securityPassword.vue'),
              },
            ],
          },
          {
            path: 'seting',
            component: BlankView,
            children: [
              {
                path: 'freight',
                component: BlankView,
                children: [
                  {
                    path: 'create/goods',
                    component: () => import('@/views/home/children/shop/children/express/create/goods.vue'),
                  },
                  {
                    path: 'create/order',
                    component: () => import('@/views/home/children/shop/children/express/create/order.vue'),
                  },
                  {
                    path: 'create/group',
                    component: () => import('@/views/home/children/express/children/freight/create/group.vue'),
                  },
                  {
                    path: 'edit/goods/:id',
                    component: () => import('@/views/home/children/shop/children/express/edit/goods.vue'),
                  },
                  {
                    path: 'edit/order/:id',
                    component: () => import('@/views/home/children/shop/children/express/edit/order.vue'),
                  },
                  {
                    path: 'edit/group/:id',
                    component: () => import('@/views/home/children/express/children/freight/create/group.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: 'storeHourse',
            component: () => import('@/views/home/children/shop/children/storeHourse/view.vue'),
            children: [
              { path: '', redirect: 'list' },
              {
                path: 'list',
                component: () => import('@/views/home/children/shop/children/storeHourse/storeHourse.vue'),
              },
              {
                path: 'stock/early/warning',
                component: () => import('@/views/home/children/shop/children/storeHourse/stockEarlyWarning/view.vue'),
              },
            ],
          },
          {
            path: 'storeHourse/detail/:id',
            component: () => import('@/views/home/children/shop/children/storeHourse/detail/view.vue'),
          },
          {
            path: 'finance',
            component: () => import('@/views/home/children/shop/children/finance/view.vue'),
            children: [
              { path: '', redirect: 'memberSubsidy' },
              {
                path: 'subsidy',
                component: () => import('@/views/home/children/shop/children/finance/subsidySetting/view.vue'),
              },
              {
                path: 'subsidy/productSetting',
                component: () => import('@/views/home/children/shop/children/finance/productSetting.vue'),
              },
              {
                path: 'settingSubsidyHistory',
                component: () => import('@/views/home/children/shop/children/finance/subsidySettingHistory/view.vue'),
              },
              {
                path: 'memberSubsidy',
                component: () => import('@/views/home/children/shop/children/finance/memberSubsidyInfo/list.vue'),
              },
            ],
          },
          {
            path: 'cashVerify',
            component: () => import('@/views/home/children/shop/children/cashVerify/view.vue'),
            children: [
              { path: '', redirect: 'list' },
              { path: 'list', component: () => import('@/views/home/children/shop/children/cashVerify/list.vue') },
              { path: 'export', component: () => import('@/views/home/children/shop/children/cashVerify/export.vue') },
            ],
          },
          {
            path: 'cashVerify/detail/:id',
            component: () => import('@/views/home/children/shop/children/cashVerify/detail.vue'),
          },
          {
            path: 'applets',
            component: () => import('@/views/home/children/shop/children/applets/seting.vue'),
          },
        ],
      },

      // 统计分析
      {
        path: 'statistics',
        component: () => import('@/views/home/children/statistics/view.vue'),
        children: [
          { path: 'agent', component: () => import('@/views/home/children/statistics/children/agent/view.vue') },
          {
            path: 'inventory',
            component: () => import('@/views/home/children/statistics/children/inventory/view.vue'),
          },
          { path: 'datawall', component: () => import('@/views/home/children/statistics/children/datawall/view.vue') },
          { path: 'sales', component: () => import('@/views/home/children/statistics/children/sales/list.vue') },
          {
            path: 'sales/detail/:userId',
            component: () => import('@/views/home/children/statistics/children/sales/detail.vue'),
          },
          { path: 'bonus', component: () => import('@/views/home/children/statistics/children/bonus/list.vue') },
          {
            path: 'bonus/detail/:userId',
            component: () => import('@/views/home/children/statistics/children/bonus/detail.vue'),
          },
          { path: 'income', component: () => import('@/views/home/children/statistics/children/income/view.vue') },
          {
            path: 'warehouse',
            component: () => import('@/views/home/children/statistics/children/warehouse/view.vue'),
          },
          {
            path: 'goods',
            component: () => import('@/views/home/children/statistics/children/goods/view.vue'),
            children: [
              {
                path: 'company',
                component: () => import('@/views/home/children/statistics/children/goods/child/company.vue'),
              },
              {
                path: 'agent',
                component: () => import('@/views/home/children/statistics/children/goods/child/agent.vue'),
              },
              {
                path: 'area',
                component: () => import('@/views/home/children/statistics/children/goods/child/salesArea/list.vue'),
              },
              {
                path: 'order',
                name: 'order',
                component: () => import('@/views/home/children/statistics/children/goods/child/companyOrder.vue'),
              },
              {
                path: 'send',
                name: 'send',
                component: () => import('@/views/home/children/statistics/children/goods/child/companyOrder.vue'),
              },
            ],
          },
          {
            path: 'goods/agent/export',
            component: () => import('@/views/home/children/statistics/children/goods/child/export.vue'),
          },
          {
            path: 'goods/agent/detail',
            component: () => import('@/views/home/children/statistics/children/goods/child/agentDetail.vue'),
          },
          {
            path: 'finance',
            component: () => import('@/views/home/children/statistics/children/finance/view.vue'),
            children: [
              {
                path: 'cashFlow',
                component: () => import('@/views/home/children/statistics/children/finance/child/cashFlow.vue'),
              },
              {
                path: 'agent',
                component: () => import('@/views/home/children/statistics/children/finance/child/agent.vue'),
              },
              {
                path: 'asset',
                component: () => import('@/views/home/children/statistics/children/finance/child/asset.vue'),
              },
            ],
          },
          {
            path: 'finance/export',
            component: () => import('@/views/home/children/statistics/children/finance/child/export.vue'),
          },
          {
            path: 'massiveData',
            component: () => import('@/views/home/children/statistics/children/massiveData/view.vue'),
            children: [
              {
                path: 'show',
                component: () => import('@/views/home/children/statistics/children/massiveData/massiveData.vue'),
              },
              {
                path: 'setting',
                component: () => import('@/views/home/children/statistics/children/massiveData/setting.vue'),
              },
            ],
          },
          {
            path: 'shop',
            name: 'shop',
            component: () => import('../views/home/children/statistics/children/shop/list.vue'),
          },
          {
            path: 'shop/detail',
            component: () => import('../views/home/children/statistics/children/shop/detail.vue'),
          },
        ],
      },

      // 营销活动
      {
        path: 'marketing',
        component: () => import('@/views/home/children/marketing/view.vue'),
        children: [
          {
            path: 'threshold',
            component: () => import('@/views/home/children/marketing/children/threshold/list.vue'),
          },
          {
            path: 'threshold/edit',
            component: () => import('@/views/home/children/marketing/children/threshold/edit.vue'),
          },
          { path: 'limitbuy', component: () => import('@/views/home/children/marketing/children/limitbuy/list.vue') },
          {
            path: 'limitbuy/edit/:id',
            component: () => import('@/views/home/children/marketing/children/limitbuy/edit.vue'),
          },
        ],
      },
      // 推荐代理业绩测试页面
      { path: 'bonusTest/list', component: () => import('@/views/home/children/bonusTest/list.vue') },
      { path: 'bonusTest/detail/:id', component: () => import('@/views/home/children/bonusTest/detail.vue') },
      // 临时：导入代理/会员
      { path: 'temp/importUser', component: () => import('@/views/temp/importUser/view.vue') },
      // 无访问权限
      {
        path: 'notAllow',
        component: () => import('@/views/home/children/notAccess/view.vue'),
      },
    ],
  },
  {
    path: '*',
    component: {
      render() {
        return `<h1>404</h1>`;
      },
    },
  },
];

export default routes;
