<script>
import { Tabs } from 'element-ui';
import TabPane from './TabPane.vue';

const El = { Tabs };

export default {
  name: 'main-layout-tabs',
  props: {
    router: Boolean,
    tabs: {
      type: Array,
      default: () => [],
    },
    value: {},
    disabled: Boolean,
  },
  data() {
    return {
      activeName: this.value,
      tabsList: [],
    };
  },
  computed: {
    tabsMap() {
      return this.tabsList.reduce((map, tab) => ({ ...map, [tab.name]: tab }), {});
    },
  },
  methods: {
    onTabClick(tab) {
      if (!this.router) return;

      tab = this.tabsMap[tab.name];

      if (tab.replace) {
        this.$router.replace(tab.route);
      } else {
        this.$router.push(tab.route);
      }
    },
    updateActive() {
      if (!this.router) return;
      const active = [...this.tabsList]
        .sort((a, b) => b.route.path.length - a.route.path.length)
        .find((tab) => {
          if (!tab.route) return false;
          return this.$route.path.indexOf(tab.route.path) === 0;
        });

      if (active) {
        this.activeName = active.name;
        return;
      }
      if (this.tabsList[0]) {
        const firstTab = this.tabsList[0];
        this.activeName = this.tabsList[0].name;
        this.$router.replace(firstTab.route.path);
      }
    },
  },
  watch: {
    '$route.path'() {
      this.$nextTick(() => {
        this.updateActive();
      });
    },
    value() {
      this.activeName = this.value;
    },
    activeName() {
      this.$emit('input', this.activeName);
    },
    tabs() {
      this.tabsList = [];
      this.$nextTick(() => {
        this.tabsList = this.tabs.map((tab) => {
          const route = formatRoute(tab.route);
          return {
            ...tab,
            name: tab.name || route.path,
            route,
          };
        });
      });
    },
  },
  created() {
    this.$nextTick(() => {
      this.tabsList = this.tabs.map((tab) => {
        const route = formatRoute(tab.route);
        return {
          ...tab,
          name: tab.name || route.path,
          route,
        };
      });
      this.updateActive();
    });
  },
  render() {
    return (
      <div class="main-layout-tabs">
        <El.Tabs
          {...{
            on: {
              ...this.$listeners,
              'tab-click': (event) => {
                this.$listeners['tab-click'] && this.$listeners['tab-click'](event);

                this.onTabClick(event);
              },
              input: (value) => {
                this.activeName = value;
              },
            },
          }}
          class="main-layout-tabs__tabs"
          value={this.activeName}
        >
          {this.$slots.default ||
            this.tabsList.map((tab) => {
              return (
                <TabPane name={tab.name} badge={tab.badge} disabled={this.disabled || tab.disabled}>
                  {tab.label}
                </TabPane>
              );
            })}
        </El.Tabs>
      </div>
    );
  },
};

function formatRoute(route) {
  if (typeof route === 'string') {
    return { path: route };
  }
  return route;
}
</script>

<style lang="postcss">
@import '~@/styles/base/var.css';

.main-layout-tabs {
  padding-left: 30px;

  & .el-tabs__header {
    margin: 0;
  }

  & .el-tabs__nav-wrap {
    &:after {
      display: none;
    }
  }
}
</style>
