import request from '@hjgy/common/request';

/**
 * 添加用户操作密码
 */
export const addUserPasswordInfo = (params) => request.post(`/operate-password/add-user-password-info`, params);

/**
 * 批量操作管理员/代理页面设置项
 */
export const batchUpdatePwdPageSetting = (params) =>
  request.post(`/operate-password/batch-update-pwd-page-setting`, params);

/**
 * 根据code查询页面设置项
 */
export const getUserPwdPageSettingDto = ({ code }) =>
  request.get(`/operate-password/get-user-pwd-page-setting-dto/${code}`);

/**
 * 根据品牌商id查询页面设置项
 */
export const getUserPwdPageSettingList = () => request.get(`/operate-password/get-user-pwd-page-setting-list`);

/**
 * 重置密码
 */
export const resetPassword = (params) => request.post(`/operate-password/reset-password`, params);

/**
 * 修改密码
 */
export const updatePassword = (params) => request.post(`/operate-password/update-password`, params);

/**
 * 修改开关
 */
export const updatePwdSwitch = (params) => request.post(`/operate-password/update-pwd-switch`, params);

/**
 * 更改当前设置项
 */
export const updateUserPwdPageSetting = (params) =>
  request.post(`/operate-password/update-user-pwd-page-setting`, params);

/**
 * 校验密码
 */
export const verifyPassword = (params) => request.post(`/operate-password/verify-password`, params);

/**
 * 检查用户密码设置
 */
export const whetherUserNeedPwd = (params) => request.get(`/operate-password/whether-user-need-pwd`, params);

/**
 * 根据用户id查询是否开启密码
 */
export const getCheck = (userId) => request.get(`/operate-password/check/${userId}`);
