<script>
import MenuLayoutMenu from './MenuLayoutMenu.vue';

export default {
  name: 'menu-layout',
  props: {
    title: String,
    menus: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    renderMenu() {
      if (this.$slots.menu) return this.$slots.menu;

      return <MenuLayoutMenu menus={this.menus} />;
    },
  },
  render() {
    const notLoading = !!this.$slots.default;
    return (
      <div class="menu-layout">
        <div class="menu-layout__left">
          <div class="menu-layout__title">{this.$slots.title || this.title}</div>

          {this.renderMenu()}
        </div>

        <div class="menu-layout__body" v-loading={!notLoading}>
          {this.$slots.default}
        </div>
      </div>
    );
  },
};
</script>

<style lang="postcss">
@import '~@/styles/base/var.css';

.menu-layout {
  display: flex;
  height: 100%;

  & .menu-layout__left {
    box-sizing: border-box;
    width: 150px;
    height: 100%;
    border-right: 1px solid #e4e7ed;
    background-color: #fafafa;

    & .menu-layout__title {
      box-sizing: border-box;
      height: 40px;
      padding-left: 20px;
      border-bottom: 1px solid #e4e7ed;
      display: flex;
      align-items: center;

      font-size: 15px;
      line-height: 15px;
      background-color: #ffffff;

      &:before {
        margin-right: 7px;
        display: block;
        content: '';
        width: 3px;
        height: 15px;
        border-radius: 1.5px;
        background: var(--color-primary);
      }
    }
  }

  & .menu-layout__body {
    flex: 1;
    overflow: auto;
  }
}
</style>
